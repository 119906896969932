import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import CajasFrias from '../CadenaDeFrio/CajasFrias.jpg'
import Termos1 from '../CadenaDeFrio/Termos1.jpg'
import { CardColumns, Row, Col, Card } from 'react-bootstrap'

export class ColdChain extends Component{
    render(){
        return(
            <div>
                <Header/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Cold chain</h1></Col>
                    <Col></Col>
                </Row>
                <br/>
                <CardColumns className="text-center">
                    <Card >
                        <Card.Img variant="top" src={CajasFrias}/>
                        <Card.Body>
                            <Card.Title>Cold boxes</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card >
                        <Card.Img variant="top" src={Termos1}/>
                        <Card.Body>
                            <Card.Title>Thermos</Card.Title>
                        </Card.Body>
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}