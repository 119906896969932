import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Row, Col, Container } from 'react-bootstrap'

export class MedCommercial extends Component{
    render(){
        return(
            <div>
                <Header />
                <Container className="fondo-texto">
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Commercial</h1></Col>
                    <Col></Col>
                </Row>
                <br/>
                <p>
                <strong>Wholesale.</strong> Some of the houses we work with: Capsules – Tablets, Syrup Jar, Suspension, Vial – Blister
                </p>
                <br/>
                <Row>
                    <Col>
                    <ul>
                        <li>3M PHARMA</li>
                        <li>ABBOTT</li>
                        <li>ABSORBENTES</li>
                        <li>ADAMED</li>
                        <li>ADECAST</li>
                        <li>ALFER</li>
                        <li>AMBIDERM</li>
                        <li>AMERICA</li>
                        <li>AMICELCO, S. A.</li>
                        <li>AMILAB</li>
                        <li>ANCALMO</li>
                        <li>ARSAL</li>
                        <li>ASOFARMA</li>
                        <li>ASTA MEDICA</li>
                        <li>BABY CHIC</li>
                        <li>BAGO</li>
                        <li>BAYER</li>
                        <li>BAYER_FARMA</li>
                        <li>BDF</li>
                        <li>BENESTAR</li>
                        <li>BIC</li>
                        <li>BIOMEDICAL</li>
                        <li>BIOQUIMICAS</li>
                        <li>BMA Pharma</li>
                        <li>BODEGA DE CONSUMO</li>
                        <li>BOGA</li>
                        <li>BOHERINGER</li>
                        <li>BONIN</li>
                        <li>BRISTOL-MYERS SQUIBB</li>
                        <li>BUSSIE</li>
                        <li>CADBURY ADAMS</li>
                        <li>CALIQUIMICA</li>
                        <li>CAPLIN POINT</li>
                        <li>CENTRO DISTRIBUIDOR, S. A</li>
                        <li>CHEMILCO INTERNACIONAL</li>
                        <li>CHINOIN</li>
                        <li>CINFA</li>
                        <li>CITOCOLOR</li>
                        <li>CODISA</li>
                        <li>COFARM</li>
                        <li>COLGATE PALMOLIVE</li>
                        <li>COTEFASA</li>
                        <li>CUREBAND</li>
                        <li>DAVIS</li>
                        <li>DIPROFARM, S. A.</li>
                        <li>DIQUIVA, S. A.</li>
                        <li>DISFAVIL</li>
                        <li>DISOVA</li>
                        <li>DISTAMPAR</li>
                        <li>DONOVAN WERKE</li>
                        <li>EDIGAR</li>
                        <li>ELU</li>
                        <li>EMILIO HERRERA</li>
                        <li>EVENFLO</li>
                        <li>EVEREADY</li>
                        <li>FARMA QUIMICA</li>
                        <li>FARMACAPS</li>
                        <li>FARMAMEDICA</li>
                        <li>FRESKESITO</li>
                        <li>FRYCIA</li>
                        <li>FUTURA</li>
                        <li>GAMMA</li>
                        <li>GARNIER</li>
                        <li>GB PHARMA</li>
                        <li>GENERICS (2238 – 2335)</li>
                        <li>GENOMALAB</li>
                        <li>GERBER</li>
                        <li>GLAXO PHARMA</li>
                    </ul>
                    </Col>
                    <Col>
                    <ul>
                        <li>GLAXO SMITHKLINE</li>
                        <li>GRISI</li>
                        <li>GRUNENTHAL</li>
                        <li>GRUPO FARMA</li>
                        <li>HAEUSSLER</li>
                        <li>HCK JIMCORP</li>
                        <li>HELOS</li>
                        <li>HERBRUGER</li>
                        <li>HUGGIES</li>
                        <li>ICA</li>
                        <li>ICN</li>
                        <li>IKSAM</li>
                        <li>INFASA</li>
                        <li>INPROFAR</li>
                        <li>INTECFA</li>
                        <li>INTERFARM</li>
                        <li>ISIS PHARMA</li>
                        <li>ITALBIOTIC</li>
                        <li>JANSSEN</li>
                        <li>JHONSON CONSUMO</li>
                        <li>JOHNSON BABY</li>
                        <li>KANDY</li>
                        <li>KENLY ROCHAS</li>
                        <li>KIMBERLY KLARK</li>
                        <li>KRAL</li>
                        <li>LA SANTE</li>
                        <li>LABORATORIOS UNIDOS</li>
                        <li>LAFRANCOL</li>
                        <li>LAINEZ</li>
                        <li>LANCASCO</li>
                        <li>LAPRIN</li>
                        <li>LAPRIN OTC</li>
                        <li>LASANA</li>
                        <li>LEROY-ELAS TOMEDICA</li>
                        <li>LEVEN</li>
                        <li>LOPEZ</li>
                        <li>LORALVA</li>
                        <li>LOREAL</li>
                        <li>LOS DOS</li>
                        <li>MCKESSON</li>
                        <li>MEAL LITE</li>
                        <li>MEDIC</li>
                        <li>MEDINOVA</li>
                        <li>MEDIPRODUCTS</li>
                        <li>MEDLEY</li>
                        <li>MEGAPHARM</li>
                        <li>MELGAR</li>
                        <li>MENARINI</li>
                        <li>MENDEZ-RUIZ</li>
                        <li>MENTHOLATUM</li>
                        <li>MEPHA</li>
                        <li>MERCAFARMA</li>
                        <li>MERCK</li>
                        <li>METTLER</li>
                        <li>MILAGRO DE LA SELVA</li>
                        <li>MONERAUX</li>
                        <li>NIELSEN PHARMA (2269-7729)</li>
                        <li>NITRO</li>
                        <li>NLSA</li>
                        <li>NOVARTIS</li>
                        <li>NOVUM</li>
                        <li>NUN`Z</li>
                        <li>NYAL</li>
                        <li>OM PHARMA</li>
                        <li>ORAN</li>
                        <li>ORION</li>
                        <li>PADES</li>
                        <li>PAILL</li>
                    </ul>
                    </Col>
                    <Col>	
                    <ul>
                        <li>PASMO</li>
                        <li>PFIZER</li>
                        <li>PHARMAKIN</li>
                        <li>PHARMALAT</li>
                        <li>PHARMATON</li>
                        <li>PHENIEL J. M.</li>
                        <li>PHISA</li>
                        <li>PIERSAN</li>
                        <li>PLASTYMET</li>
                        <li>PROCTER & GAMBLE</li>
                        <li>PRODIAGNOSTIC</li>
                        <li>PRODIN</li>
                        <li>PRODUCTOS NUEVOS</li>
                        <li>SELECTPUARMA</li>
                        <li>PROFONSA</li>
                        <li>PROTEIN</li>
                        <li>QUALIPHARM</li>
                        <li>QUIFARMA</li>
                        <li>QUIMIFAR</li>
                        <li>REDI</li>
                        <li>ROBERPHARM</li>
                        <li>ROCHE</li>
                        <li>ROLAND LOUIS</li>
                        <li>ROSSATTA</li>
                        <li>ROTTA</li>
                        <li>ROWA</li>
                        <li>ROWE</li>
                        <li>SAN LUIS</li>
                        <li>SANDOZ</li>
                        <li>SANOFI-AVENTIS</li>
                        <li>SANTA ANA</li>
                        <li>SANTA FE</li>
                        <li>SANTE</li>
                        <li>SC JOHNSON</li>
                        <li>SCA</li>
                        <li>SCHERING</li>
                        <li>SCHERING GUATEMALTECA</li>
                        <li>SCHERING PLOUGH</li>
                        <li>SEARLE</li>
                        <li>SELECTFARMA</li>
                        <li>SENOSIAN</li>
                        <li>SENSAT</li>
                        <li>SERVIER</li>
                        <li>SIERRA</li>
                        <li>STEIN</li>
                        <li>SUDORIN</li>
                        <li>SUIZOS</li>
                        <li>SUPERIOR</li>
                        <li>TG MEDICALS</li>
                        <li>THERFAM</li>
                        <li>TRINIDAD</li>
                        <li>TRINOMED</li>
                        <li>UNILEVER</li>
                        <li>UNIPHARM</li>
                        <li>USETHICALS</li>
                        <li>VARIOS</li>
                        <li>VESA</li>
                        <li>VIDA</li>
                        <li>VIJOSA</li>
                        <li>VIZCAINO</li>
                        <li>WALKIRIA</li>
                        <li>WASHINGTON</li>
                        <li>WINZZER</li>
                        <li>WOOSAM MEDICAL</li>
                        <li>WYETH</li>
                        <li>WYSE CHOICE</li>
                        <li>Z.MEDICAL</li>
                        <li>ZUUM</li>
                    </ul>
                    </Col>
                </Row></Container>
                <Footer/>
            </div>
        )
    }
}