import img10 from './Rehabilitacion/img10.jpg'
import img12 from './Rehabilitacion/img12.jpg'
import img33 from './Rehabilitacion/img33.jpg'
import img34 from './Rehabilitacion/img34.jpg'
import img36 from './Rehabilitacion/img36.jpg'
import img35 from './Rehabilitacion/img35.jpg'
import img37 from './Rehabilitacion/img37.jpg'
import img64 from './EquipoMedico/img64.jpg'
import img69 from './EquipoMedico/img69.jpg'
import {Footer} from './Footer'
import React, {Component} from 'react'
import { Card, CardColumns, Row, Col } from 'react-bootstrap'
import {Navigation} from './Navigation'


export class Encamamiento extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Encamamiento</h1></Col>
                    <Col></Col>
                </Row> 
                <br/>
                <CardColumns className="text-center">
                    <Card>
                        <Card.Img variant="top" src={img33}/>
                        
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={img36}/>
                    
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={img34}/>
                    
                    </Card>
                    <Card className="justify-content-center">
                        <Card.Img variant="top" src={img37}/>
                    
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={img35}/>
                    
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={img64} />
                        
                    </Card>
                    
                    <Card>
                        <Card.Img variant="top" src={img10}/>
                    
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={img12}/>
                    
                    </Card>

                    <Card>
                        <Card.Img variant="top" src={img69} />   
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}