import React, {Component} from 'react'
import {Navbar, Nav, NavDropdown} from 'react-bootstrap'

import { Row, Col } from 'react-bootstrap'
import DRYCOM from './Encabezado/DRYCOM.jpg'
import Image from 'react-bootstrap/Image'
import "./Navigation.css";


export class Navigation extends Component{
    render(){
        return(
            <div>
            <Row>
                <Col></Col>
                <Col className="d-flex justify-content-center">
                <Image src={DRYCOM} width="65%"></Image>
                </Col>
                <Col></Col>
            </Row>
            <Navbar collapseOnSelect bg="primary" expand="lg" variant="dark">
            <Navbar.Toggle className="justify-content-center"/>
            <Navbar.Collapse>
                <Nav>
                    <Nav.Link href="/">Inicio</Nav.Link>
                    <Nav.Link href="/cadenaFrio">Cadena de frío</Nav.Link>
                    <Nav.Link href="/instrumental">Instrumental</Nav.Link>
                    <Nav.Link href="/insumo">Insumo medico</Nav.Link>
                    <NavDropdown title="Medicamento">
                        <NavDropdown.Item href="/comerciales">Comerciales</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/generico">Genéricos</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Mobiliario y equipo">
                        <NavDropdown.Item href="/clinico">Clínico</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/diagnostico">Diagnóstico</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/emergencia">Emergencia</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/encamamiento">Encamamiento</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/ginecologia">Ginecología</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/intensivo">Intensivo</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/equipoMedico">Médico/Educativo</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/neonatologia">Neonatología</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/talla">Peso y Talla</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/rehabilitacion">Rehabilitación</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/operaciones">Sala de Operaciones</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/auxilios">1eros Auxilios</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/contacto">Contactenos</Nav.Link>                  
                </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                Go to page in <a href="/en/Home">English</a>
                </Navbar.Text>
            </Navbar.Collapse>    
            </Navbar>
            </div>
        )
    }
}