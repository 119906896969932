import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { CardDeck, Card, Pagination, Container, Row, Col } from 'react-bootstrap'
import img18 from '../EquipoMedico/img18.jpg'
import img23 from '../EquipoMedico/img23.jpg'
import img24 from '../EquipoMedico/img24.jpg'
import img25 from '../EquipoMedico/img25.jpg'
import img26 from '../EquipoMedico/img26.jpg'
import img27 from '../EquipoMedico/img27.jpg'
import img28 from '../EquipoMedico/img28.jpg'
import img29 from '../EquipoMedico/img29.jpg'
import img30 from '../EquipoMedico/img30.jpg'
import img31 from '../EquipoMedico/img31.jpg'
import img32 from '../EquipoMedico/img32.jpg'
import img33 from '../EquipoMedico/img33.jpg'
import img34 from '../EquipoMedico/img34.jpg'
import img35 from '../EquipoMedico/img35.jpg'
import img36 from '../EquipoMedico/img36.jpg'
import img37 from '../EquipoMedico/img37.jpg'
import img38 from '../EquipoMedico/img38.jpg'
import img39 from '../EquipoMedico/img39.jpg'
import img40 from '../EquipoMedico/img40.jpg'
import img41 from '../EquipoMedico/img41.jpg'

export class MedicalInput2 extends Component{
    render(){
        return(
            <div>
                <Header/>
                <Container><br/>
                <Pagination className="justify-content-center" size="lg">
                <Pagination.Item href="/en/MedicalInput">{1}</Pagination.Item>
                <Pagination.Item active>{2}</Pagination.Item>
                <Pagination.Item href="/en/MedicalInput3">{3}</Pagination.Item>
                </Pagination>
                <br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Silk tape</Card.Title>
                                <Card.Text>	1/2" x 10 1.25x10 yards<br/>
                                            24 rolls box; 48 boxes/carton<br/>
                                            44x32. 5x33.5<br/>
                                            1" 2.5x10 yards 12 rolls/box<br/>
                                            48 boxes/carton<br/>
                                            44x32.5x33.5<br/>
                                            2"5x10 yards 6 rolls/box<br/>
                                            48 boxes/carton 44x32.5x33.5
                                </Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img18} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Micropore</Card.Title>
                                <Card.Text> 1.25x10yards 24 rolls / box<br/>
                                            48 boxes / carton 44x32.5x33<br/>
                                            2.5x10yards 12 rolls / box<br/>
                                            48 boxes / carton 44x32 5x33.5<br/>
                                            6 rolls 5x10yards box<br/>
                                            48 boxes / carton 44x32.5x33.5
                                            7.5X10yards.</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img23} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Masks</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img24} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Pericraneals</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img25} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Urine Bags</Card.Title>
                                <Card.Text>Pediatric </Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img26} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Surgeon Clothing</Card.Title>
                                <Card.Text>Disposable, Variety of colors</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img27} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Surgeon Clothing</Card.Title>
                                <Card.Text>Disposable, Variety of colors</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img28} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Stomach Catheter</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img29} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Foley Catheter</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img30} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Levin Catheter</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img31} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Foley Catheter</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img32} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Nelaton Catheter</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img33} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Rectal Catheter</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img34} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Stomach Catheters</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img35} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Endotracheal tubes</Card.Title>
                                <Card.Text>Variety of Styles</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img36} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Endotracheal tubes</Card.Title>
                                <Card.Text>Variety of Styles</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img37} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Serum Set</Card.Title>
                                <Card.Text>Aired on "Y"</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img38} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Disposable syringes</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img39} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Disposable gloves</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img40} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Wooden swabs</Card.Title>
                                    <Card.Text>Bag of 100 Units</Card.Text>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img41} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                </CardDeck><br/>

                </Container>
            
                <Pagination className="justify-content-center" size="lg">
                <Pagination.Item href="/en/MedicalInput">{1}</Pagination.Item>
                <Pagination.Item active>{2}</Pagination.Item>
                <Pagination.Item href="/en/MedicalInput3">{3}</Pagination.Item>
                </Pagination>
                <Footer/>
            </div>
        )
    }
}