import img67 from './EquipoMedico/img67.jpg'
import img68 from './EquipoMedico/img68.jpg'
import {Footer} from './Footer'
import React, {Component} from 'react'
import { Card, CardColumns, Row, Col } from 'react-bootstrap'
import {Navigation} from './Navigation'

export class Operaciones extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Sala de Operaciones</h1></Col>
                    <Col></Col>
                </Row><br/>
                <CardColumns className="text-center">
                    <Card>
                        
                        <Card.Img variant="top" src={img67} />
                        <Card.Body>
                            <Card.Title>Sala de operaciones I</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card>
                        
                        <Card.Img variant="top" src={img68} />
                        <Card.Body>
                            <Card.Title>Sala de operaciones II</Card.Title>
                        </Card.Body>
                    </Card>
                    
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}