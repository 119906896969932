import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Row, Col, Container } from 'react-bootstrap'

export class MedGeneric extends Component{
    render(){
        return(
            <div>
                <Header/>
                <Container className="fondo-texto">
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Generic</h1></Col>
                    <Col></Col>
                </Row>
                <br/>
                <p>
                    <strong>Wholesale.</strong> Tablets, Syrup Jar, Suspension, Vial
                </p>
                <br></br>
                <Row>
                    <Col>
                    <ul>
                        <li>Acetaminophen</li>
                        <li>Acyclovir</li>
                        <li>Acetylsalicylic acid of 500mg</li>
                        <li>Folic acid</li>
                        <li>Valproic acid</li>
                        <li>Sterile water</li>
                        <li>Albendazole</li>
                        <li>Alka Seltzer </li>
                        <li>Alopurinol</li>
                        <li>Coal tar + salicylic acid</li>
                        <li>Ambroxol</li>
                        <li>Amikacin</li>
                        <li>Amoxicillin + Clavulanic Acid</li>
                        <li>Amoxicillin</li>
                        <li>Ampicillin</li>
                        <li>Aspirin</li>
                        <li>Azithromycin</li>
                        <li>Sulfur and white petrolatum</li>
                        <li>Bacillus Clausii</li>
                        <li>Benzetacil</li>
                        <li>Benzoyl peroxide</li>
                        <li>Betamethasone + salicylic acid</li>
                        <li>Betamethasone valerate</li>
                        <li>Sodium bicarbonate</li>
                        <li>Biperiden hydrochloride</li>
                        <li>Fenoterol Hydrobromide</li>
                        <li>Bromide</li>
                        <li>Ipratropium Bromide</li>
                        <li>Otilonium Bromide</li>
                        <li>Vecuronium Bromide</li>
                        <li>Budesonide</li>
                        <li>Carbamazepine</li>
                        <li>Caspofungin</li>
                        <li>Cefadroxil</li>
                        <li>Cephalothin</li>
                        <li>Cefepime</li>
                        <li>Cefixime</li>
                        <li>Cefotaxime</li>
                        <li>Ceftazidime</li>
                        <li>Ceftriaxone</li>
                        <li>Ciclosporin microemulsion</li>
                        <li>Ciprofloxacin</li>
                        <li>Clarithromycin</li>
                        <li>Clindamycin</li>
                        <li>Clobetasol propionate</li>
                        <li>Chloramphenicol</li>
                        <li>Chlorpheniramine maleate</li>
                        <li>Metformin Hydrochloride</li>
                        <li>Chloroquine phosphate</li>
                        <li>Potassium chloride</li>
                        <li>Sodium chloride</li>
                        <li>Clotrimazole</li>
                        <li>Colchicine</li>
                        <li>Complex B</li>
                    </ul>
                    </Col>
                    <Col>
                    <ul>
                        <li>Dexketoprofen trometamol</li>
                        <li>Dextrose solution</li>
                        <li>Dextrose and Sodium Chloride</li>
                        <li>Diclofenac</li>
                        <li>Dicloxacillin</li>
                        <li>Diphenidol</li>
                        <li>Dimenhydrinate</li>
                        <li>Beclomethasone Dipropionate</li>
                        <li>Dorival</li>
                        <li>Doxycycline</li>
                        <li>Epinephrine</li>
                        <li>Ergotamine in association</li>
                        <li>Erythromycin</li>
                        <li>Esomeprazole</li>
                        <li>Streptomycin sulfate</li>
                        <li>Phenytoin sodium</li>
                        <li>Fluconazole</li>
                        <li>Gentamicin</li>
                        <li>Glimepiride</li>
                        <li>Hydrocortisone</li>
                        <li>Hydroxychloroquine sulfate</li>
                        <li>Aluminum Hydroxide, Magnesium</li>
                        <li>Aminochelated iron + folic acid</li>
                        <li>Iron saccharate or citrate</li>
                        <li>Hyoscine</li>
                        <li>Ibuprofen</li>
                        <li>Ipratropium</li>
                        <li>Itraconazole</li>
                        <li>Ketoconazole</li>
                        <li>Ketoprofen</li>
                        <li>Lactobacillus Reuteri Protectis</li>
                        <li>Lactulose</li>
                        <li>Lansoprazole</li>
                        <li>Leflunomide</li>
                        <li>Levetiracetam</li>
                        <li>Levofloxacin</li>
                        <li>Lidocaine with epinephrine</li>
                        <li>Magnesium sulfate</li>
                        <li>Mamotrigine</li>
                        <li>Metamizole Sodium</li>
                        <li>Metronidazole</li>
                        <li>Metronidazole + Nystatin</li>
                        <li>Moxifloxacin</li>
                        <li>Multivitamins</li>
                        <li>Multivitamins for infusion</li>
                        <li>Neomycin</li>
                        <li>Nifedipine</li>
                        <li>Nystatin</li>
                        <li>Silver nitrate</li>
                        <li>Nitrofurantoin</li>
                        <li>Ofloxacin</li>
                        <li>Oxcarbazepine</li>
                        <li>Zinc oxide</li>
                    </ul>
                    </Col>
                    <Col>
                    <ul>
                        <li>Zinc Oxide + Calamine</li>
                        <li>Pyrantel Pamoate</li>
                        <li>Pancrealipase</li>
                        <li>Benzathine Penicillin G</li>
                        <li>Procaine Penicillin G</li>
                        <li>Sodium Penicillin G Crystalline</li>
                        <li>Penicillins, among others</li>
                        <li>Permethrin</li>
                        <li>Phyllium plantago</li>
                        <li>Piperacillin / Tazobactam</li>
                        <li>Pyrimethamine / Sulfadoxine</li>
                        <li>Polymyxin B</li>
                        <li>Prednicarbate</li>
                        <li>Prednisolone</li>
                        <li>Pregabalin</li>
                        <li>Prenatals</li>
                        <li>Primaquine phosphate or diphosphate</li>
                        <li>Propinoxate</li>
                        <li>Ranitidine hydrochloride</li>
                        <li>Ranitidine</li>
                        <li>Riluzole</li>
                        <li>Rupatadine fumarate</li>
                        <li>Salbutamol</li>
                        <li>Oral rehydration salts</li>
                        <li>Secnidazole</li>
                        <li>Simethicone</li>
                        <li>Simitriptan</li>
                        <li>Hartman solution</li>
                        <li>Pediatric Solution No. 1</li>
                        <li>Pediatric Solution No. 2</li>
                        <li>Saline solution</li>
                        <li>Bismuth subsalicylate</li>
                        <li>Sucralfate</li>
                        <li>Polyvalent antivenom serum</li>
                        <li>Liquid oral serum</li>
                        <li>Micronutrient Supplement</li>
                        <li>Teclozan</li>
                        <li>Thifecycline</li>
                        <li>Timolol maleate</li>
                        <li>Tinidazole</li>
                        <li>Tobramycin</li>
                        <li>Tobramycin/Dexamethasone</li>
                        <li>Topiramate</li>
                        <li>Tramadol Hydrochloride</li>
                        <li>Trimethoprim – Sulfamethoxazole</li>
                        <li>Vitamin "A" pearl of 50,000 IU</li>
                        <li>Vitamin A</li>
                        <li>Vitamin B 12</li>
                        <li>Vitamin B1</li>
                        <li>Vitamin C</li>
                        <li>Vitamin D</li>
                        <li>Vitamin K</li>
                        <li>Neurotropic Vitamins</li>
                    </ul>
                    </Col>
                </Row>
                </Container>
                <Footer/>
            </div>
        )
    }
}