import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Card, CardColumns, Col, Row } from 'react-bootstrap'
import img71 from '../EquipoMedico/img71.jpg'
import img72 from '../EquipoMedico/img72.jpg'
import img73 from '../EquipoMedico/img73.jpg'

export class FirstAids extends Component{
    render(){
        return(
            <div>
                <Header/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>First aids</h1></Col>
                    <Col></Col>
                </Row><br/>
                <CardColumns className="text-center">
                <Card>
                    <Card.Img variant="top" src={img71} />
                    <Card.Body>
                        <Card.Title>Briefcases and backpacks</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img72} />
                    <Card.Body>
                        <Card.Title>Accessories</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img73} />
                    <Card.Body>
                        <Card.Title>Other accessories</Card.Title>
                    </Card.Body>
                </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}