import Calidad from './Encabezado/Calidad.png'
import Tarjetas from './Encabezado/Tarjetas.png'
import {Footer} from './Footer'
import React, {Component} from 'react'
import { Col, Row, Carousel, Image, Container } from 'react-bootstrap';
import  EquipoMedico  from './Inicio/EquipoMedico.jpg';
import  Medicamentos  from './Inicio/Medicamentos.jpg';
import  Instrumental  from './Inicio/Instrumental.jpg';
import  Marcas  from './Inicio/Marcas.jpg';

import {Navigation} from './Navigation'
import "./Home.css";

export class Home extends Component{
    render(){
        return(
            <div>
            <Navigation/>
            <Container>
                <Carousel className="carousel slide carousel-fade fondo-ca">
                    <Carousel.Item>
                        <a href="/equipoMedico">
                            <Image
                            className="d-block mx-auto img-fluid"
                            width="64%"
                            src= { EquipoMedico }
                            alt="Eq medico"
                            />    
                        </a>
                        <Carousel.Caption>
                        <h3 className="text-light bg-dark">Equipo medico</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block mx-auto img-fluid"
                        width="80%"
                        src= { Medicamentos }
                        alt="Third slide"
                        />
                        <Carousel.Caption>
                        <h3 className="text-light">Medicamento</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <a href="/instrumental">
                            <img
                            className="d-block mx-auto img-fluid"
                            width="71%"
                            src= { Instrumental }
                            alt="Third slide"
                            />
                        </a>
                        <Carousel.Caption>
                        <h3 className="text-light bg-dark">Instrumental</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
                <br></br>
            <div className="fondo-texto">
                <h1>¡Bienvenidos a Drycom!</h1>
                <p className="text-justify mt-4 mb-4">Somos una empresa dedicada a la distribución al <strong>mayoreo</strong> de equipo médico, equipo de laboratorio y medicamentos, tanto en el área privada como gubernamental en Guatemala. Somos pioneros en la distribución de equipos y medicamentos de alta calidad y confiables. Contamos con:</p>
            
                <Row>
                <Col>
                <ul >
                    <li>Equipo de diagnóstico</li>
                    <li>Equipo para cadena de frío</li>
                    <li>Examen y lámparas</li>
                    <li>Instrumentación acero inoxidable</li>
                    <li>Medicamentos comerciales</li>
                    <li>Medicamentos genéricos</li>
                    <li>Mobiliario para rehabilitación</li>
                </ul>
                </Col>
                
                <Col>
                <ul>
                    <li>Muebles y equipos médicos</li>
                    <li>Peso y talla</li>
                    <li>Productos descartables</li>
                    <li>Productos médicos</li>
                    <li>Seguridad para el baño</li>
                    <li>Suturas</li>
                    <li>Todo lo referente al sector salud</li>
                </ul>
                </Col>
                </Row>
                <p>Concactenos para más información</p>
                
            </div>
            <br></br>
            </Container>
            <Row className="fondo-texto">
                <Col className="border-right border border-dark">
                    <h2 className="text-center">Calidad de nuestros productos</h2>
                    <br></br>
                    <p className="text-center">
                    <br/><br/>
                    <Image src={Calidad} width="70%" className="border-0 rounded"></Image>
                    </p>
                </Col>
                <Col className="border-dark fondo-ca">
                    <br></br>
                    <div className="fb-page d-flex justify-content-center" data-href="https://www.facebook.com/Drycom-Drogueria-Distribuidora-351242224953844/" data-tabs="timeline" data-width="450" data-height="700" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/Drycom-Drogueria-Distribuidora-351242224953844/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Drycom-Drogueria-Distribuidora-351242224953844/">Drycom Drogueria - Distribuidora</a></blockquote></div>
                    <br></br>                
                </Col>
                <Col className="border-right border border-dark">
                    <h2 className="text-center">Algunas de nuestras marcas</h2>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <Image src={Marcas} width="100%"/>
                </Col>
            </Row>
            <br></br>
            <Row>
            <Col></Col>
            <Col className="d-flex justify-content-center">
            <Image src={Tarjetas} width="60%"></Image>
            <br />
            </Col>
            <Col></Col>
            </Row>
            <br></br>
            <Footer/>
            </div>
        )
    }
}