import img1 from './EquipoMedico/img1.jpg'
import img2 from './EquipoMedico/img2.jpg'
import img3 from './EquipoMedico/img3.jpg'
import img4 from './EquipoMedico/img4.jpg'
import img5 from './EquipoMedico/img5.jpg'
import img6 from './EquipoMedico/img6.jpg'
import img7 from './EquipoMedico/img7.jpg'
import img8 from './EquipoMedico/img8.jpg'
import img9 from './EquipoMedico/img9.jpg'
import img10 from './EquipoMedico/img10.jpg'
import img11 from './EquipoMedico/img11.jpg'
import img12 from './EquipoMedico/img12.jpg'
import img13 from './EquipoMedico/img13.jpg'
import img14 from './EquipoMedico/img14.jpg'
import img15 from './EquipoMedico/img15.jpg'
import img16 from './EquipoMedico/img16.jpg'
import img17 from './EquipoMedico/img17.jpg'
import img22 from './EquipoMedico/img22.jpg'
import img19 from './EquipoMedico/img19.jpg'
import img20 from './EquipoMedico/img20.jpg'
import {Footer} from './Footer'
import React, {Component} from 'react'
import { CardDeck, Card, Pagination, Container, Row, Col } from 'react-bootstrap'
import {Navigation} from './Navigation'

export class Insumo extends Component{
    render(){
        return(
            <div>
            <Navigation/>
            <Container>
            <br/>
            <Pagination className="justify-content-center" size="lg">
            <Pagination.Item active>{1}</Pagination.Item>
            <Pagination.Item href="/insumo2">{2}</Pagination.Item>
            <Pagination.Item href="/insumo3">{3}</Pagination.Item>
            </Pagination>
            <br/>
                <CardDeck>
                    <Card>
                        <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col><Card.Title className="color-texto">Algodón Absorbente</Card.Title>
                            <Card.Text>	Rollo de 50 gramos.<br/>
                                        Rollo de 100 gramos.<br/>
                                        Rollo de 200 gramos.<br/>
                                        Rollo de 250 gramos.<br/>
                                        Rollo de 400 gramos.<br/>
                                        Rollo de 500 gramos.<br/>
                                        Rollo de 1000 gramos.
                            </Card.Text></Col>
                                <Col><Card.Img variant="top" src={img1} /></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                        <Row>
                            <Col>
                            <Card.Title className="color-texto">Angiocat</Card.Title>
                            <Card.Text>	Tipo pluma<br/>
                                        Mariposa con alas.<br/>
                                        Llave de tres vias.
                            </Card.Text>
                            </Col>
                            <Col>
                            <Card.Img variant="top" src={img2} />
                            </Col>
                        </Row>
                        </Card.Body>
                    </Card>
                </CardDeck>
                <br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Apósitos</Card.Title>
                                <Card.Text>	Transparentes<br/>
                                            Semi-impermeables
                                </Card.Text></Col>
                                <Col><Card.Img variant="top" src={img3} /></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Apósitos</Card.Title>
                                <Card.Text>	Transparentes<br/>
                                            Semi-impermeables
                                </Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img4} />
                                </Col>
                            </Row>  
                        </Card.Body>
                    </Card>
                </CardDeck>
                <br/>
                <CardDeck>
                    <Card>
                        <Card.Header><h5>Mobiliario y equipo</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Balanza de piso</Card.Title>
                                    <Card.Text>Para Adulto
                                    </Card.Text>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img5} />
                                </Col>
                            </Row>  
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Bolsa de Orina</Card.Title>
                                    <Card.Text>	Para Adulto 750 ml.
                                    </Card.Text>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img6} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Bolsa de Orina</Card.Title>
                                    <Card.Text>	Para Niño o Niña.
                                    </Card.Text>
                                </Col>
                                <Col><Card.Img variant="top" src={img7} /></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Bolsa Pediatrica</Card.Title>
                                <Card.Text>	Para Niño o Niña
                                </Card.Text>
                                </Col>
                                <Col><Card.Img variant="top" src={img8} /></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Brazalete ID</Card.Title>
                                    <Card.Text>Brazalete para adulto</Card.Text>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img9} />
                                </Col>
                            </Row>  
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Canula de Oxigeno</Card.Title>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img10} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Cateter</Card.Title>
                                    <Card.Text>	De infusión o Equipos de suero.</Card.Text>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img11} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Cateter de Succión</Card.Title>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img12} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Conector de Oxigeno</Card.Title>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img13} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Bolsa de Colostomía</Card.Title>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img14} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Bolsa de Orina</Card.Title>
                                    <Card.Text>	Para Pierna</Card.Text>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img15} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                        <Row>
                            <Col>
                                <Card.Title className="color-texto">Cateter de Succión</Card.Title>
                            </Col>
                            <Col>
                                <Card.Img variant="top" src={img16} />
                            </Col>
                        </Row>
                        </Card.Body>
                    </Card>
                </CardDeck> <br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Cateter de Succión</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img17} />
                                </Col>
                            </Row>
                        </Card.Body>
                        </Card>
                        {/*AQUI REGRESAMOS*/}
                        <Card>
                        <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Gorro para Paciente</Card.Title>
                                <Card.Text>Descartable,Variedad de colores</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img22} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                    <CardDeck>
                        <Card>
                        <Card.Header><h5>Productos descartables</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Card.Title className="color-texto">Espéculos</Card.Title>
                                        <Card.Text>	Descartable en Empaque Individual.</Card.Text>
                                    </Col>
                                    <Col>
                                        <Card.Img variant="top" src={img19} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                        <Card.Header><h5>Productos descartables</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Card.Title className="color-texto">Espéculos</Card.Title>
                                        <Card.Text>Descartable en Empaque Individual.</Card.Text>
                                    </Col>
                                    <Col>
                                        <Card.Img variant="top" src={img20} />
                                    </Col>
                                </Row>  
                            </Card.Body>
                        </Card>
                    </CardDeck>
                </Container>
                <br/>
                
                <Pagination className="justify-content-center" size="lg">
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item href="/insumo2">{2}</Pagination.Item>
                <Pagination.Item href="/insumo3">{3}</Pagination.Item>
                </Pagination>
                <Footer/>
            </div>
        )}}