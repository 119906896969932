import img1 from './Diagnostico/img1.jpg'
import img2 from './Diagnostico/img2.jpg'
import img3 from './Diagnostico/img3.jpg'
import img4 from './Diagnostico/img4.jpg'
import img5 from './Diagnostico/img5.jpg'
import img6 from './Diagnostico/img6.jpg'
import img7 from './Diagnostico/img7.jpg'
import img19 from './Diagnostico/img19.jpg'
import img20 from './Diagnostico/img20.jpg'
import img21 from './Diagnostico/img21.jpg'
import img22 from './Diagnostico/img22.jpg'
import img23 from './Diagnostico/img23.jpg'
import img25 from './Diagnostico/img25.jpg'
import React, {Component} from 'react'
import { Card, CardColumns, Pagination, Row, Col } from 'react-bootstrap'
import {Footer} from './Footer'
import {Navigation} from './Navigation'


export class Diagnostico2 extends Component{
    render(){
        return(
            <div>
            <Navigation/>
                <br/>
            <Row>
                <Col></Col>
                <Col className="text-center"><h1>Diagnóstico</h1></Col>
                <Col></Col>
            </Row> 
        
                <br/>
            <Pagination className="justify-content-center" size="lg">
                <Pagination.Item href="/diagnostico">{1}</Pagination.Item>
                <Pagination.Item active>{2}</Pagination.Item>
            </Pagination>
            <CardColumns className="text-center">
            {/*Primeros cuatro */}
                <Card>
                    <Card.Img variant="top" src={img1}/>
                    <Card.Body>
                        <Card.Title>Esfigmomanómetro digital</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img4}/>
                    <Card.Body>
                        <Card.Title>Esfigmomanómetro digital y libre de mercurio</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img7}/>
                    <Card.Body>
                        <Card.Title>Esfigmomanómetros</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img22}/>
                    <Card.Body>
                        <Card.Title>Estetoscopio de acero inoxidable doble campana</Card.Title>
                    </Card.Body>
                </Card>
                {/*Segunda fila */}
                <Card>
                    <Card.Img variant="top" src={img2}/>
                    <Card.Body>
                        <Card.Title>Esfigmomanómetro digital</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img5}/>
                    <Card.Body>
                        <Card.Title>Esfigmomanómetro libre de mercurio</Card.Title>
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Img variant="top" src={img21}/>
                    <Card.Body>
                        <Card.Title>Estetoscopio</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img23}/>
                    <Card.Body>
                        <Card.Title>Estetoscopio lux</Card.Title>
                    </Card.Body>
                </Card>
                
                {/*Ultima fila */}
                <Card>
                    <Card.Img variant="top" src={img3}/>
                    <Card.Body>
                        <Card.Title>Esfigmomanómetro digital</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img6}/>
                    <Card.Body>
                        <Card.Title>Esfigmomanómetro libre de mercurio y esfigmomanómetro de mesa</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img19}/>
                    <Card.Body>
                        <Card.Title>Estetoscopio de 1 y doble campana</Card.Title>
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Img variant="top" src={img20}/>
                    <Card.Body>
                        <Card.Title>Estetoscopios Rappaport</Card.Title>
                    </Card.Body>
                </Card>
            </CardColumns>
            {/*Sobrante */}
            <CardColumns className="text-center">
                <Card>
                    <Card.Img variant="top" src={img25}/>
                    <Card.Body>
                        <Card.Title>Varios</Card.Title>
                    </Card.Body>
                </Card>
            </CardColumns>
            
            <Pagination className="justify-content-center" size="lg">
                <Pagination.Item href="/diagnostico">{1}</Pagination.Item>
                <Pagination.Item active>{2}</Pagination.Item>
            </Pagination>
            <Footer/>
            </div>
        )
    }
}