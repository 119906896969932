import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { CardDeck, Card, Pagination, Container, Row, Col } from 'react-bootstrap'
import img1 from '../EquipoMedico/img1.jpg'
import img2 from '../EquipoMedico/img2.jpg'
import img3 from '../EquipoMedico/img3.jpg'
import img4 from '../EquipoMedico/img4.jpg'
import img5 from '../EquipoMedico/img5.jpg'
import img6 from '../EquipoMedico/img6.jpg'
import img7 from '../EquipoMedico/img7.jpg'
import img8 from '../EquipoMedico/img8.jpg'
import img9 from '../EquipoMedico/img9.jpg'
import img10 from '../EquipoMedico/img10.jpg'
import img11 from '../EquipoMedico/img11.jpg'
import img12 from '../EquipoMedico/img12.jpg'
import img13 from '../EquipoMedico/img13.jpg'
import img14 from '../EquipoMedico/img14.jpg'
import img15 from '../EquipoMedico/img15.jpg'
import img16 from '../EquipoMedico/img16.jpg'
import img17 from '../EquipoMedico/img17.jpg'
import img22 from '../EquipoMedico/img22.jpg'
import img19 from '../EquipoMedico/img19.jpg'
import img20 from '../EquipoMedico/img20.jpg'

export class MedicalInput extends Component{
    render(){
        return(
            <div>
            <Header/>
            <Container>
            <br/>
            <Pagination className="justify-content-center" size="lg">
            <Pagination.Item active>{1}</Pagination.Item>
            <Pagination.Item href="/en/MedicalInput2">{2}</Pagination.Item>
            <Pagination.Item href="/en/MedicalInput3">{3}</Pagination.Item>
            </Pagination>
            <br/>
                <CardDeck>
                    <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col><Card.Title className="color-texto">Absorbent cotton</Card.Title>
                            <Card.Text> Roll of 50 grams.<br/>
                                        Roll of 100 grams.<br/>
                                        Roll of 200 grams<br/>
                                        Roll of 250 grams<br/>
                                        Roll of 400 grams<br/>
                                        Roll of 500 grams<br/>
                                        Roll of 1000 grams.
                            </Card.Text></Col>
                                <Col><Card.Img variant="top" src={img1} /></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                        <Row>
                            <Col>
                            <Card.Title className="color-texto">Angiocat</Card.Title>
                            <Card.Text>	Pen type<br/>
                                        Butterfly wings<br/>
                                        Three-way key
                            </Card.Text>
                            </Col>
                            <Col>
                            <Card.Img variant="top" src={img2} />
                            </Col>
                        </Row>
                        </Card.Body>
                    </Card>
                </CardDeck>
                <br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Surgical Dressing</Card.Title>
                                <Card.Text>	Transparent<br/>
                                            Semi-waterproof
                                </Card.Text></Col>
                                <Col><Card.Img variant="top" src={img3} /></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Surgical Dressing</Card.Title>
                                <Card.Text>	Transparent<br/>
                                            Semi-waterproof
                                </Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img4} />
                                </Col>
                            </Row>  
                        </Card.Body>
                    </Card>
                </CardDeck>
                <br/>
                <CardDeck>
                    <Card>
                        <Card.Header><h5>Furniture and equipment</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Floor Scale</Card.Title>
                                    <Card.Text>Adult
                                    </Card.Text>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img5} />
                                </Col>
                            </Row>  
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Urine Bag</Card.Title>
                                    <Card.Text>Adult 750 ml.
                                    </Card.Text>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img6} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Urine Bag</Card.Title>
                                    <Card.Text>For Boy or Girl.
                                    </Card.Text>
                                </Col>
                                <Col><Card.Img variant="top" src={img7} /></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Urine Bag Pediatric</Card.Title>
                                <Card.Text>For Boy or Girl
                                </Card.Text>
                                </Col>
                                <Col><Card.Img variant="top" src={img8} /></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">ID Bracelet</Card.Title>
                                    <Card.Text>Adult</Card.Text>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img9} />
                                </Col>
                            </Row>  
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Oxygen Cannula</Card.Title>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img10} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Catheter</Card.Title>
                                    <Card.Text>Infusion or serum kits.</Card.Text>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img11} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Suction Catheter</Card.Title>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img12} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Oxygen Connector</Card.Title>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img13} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Colostomy Bag</Card.Title>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img14} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Card.Title className="color-texto">Urine Bag</Card.Title>
                                    <Card.Text>For leg</Card.Text>
                                </Col>
                                <Col>
                                    <Card.Img variant="top" src={img15} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                        <Row>
                            <Col>
                                <Card.Title className="color-texto">Suction Catheter</Card.Title>
                            </Col>
                            <Col>
                                <Card.Img variant="top" src={img16} />
                            </Col>
                        </Row>
                        </Card.Body>
                    </Card>
                </CardDeck> <br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Suction Catheter</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img17} />
                                </Col>
                            </Row>
                        </Card.Body>
                        </Card>
                        {/*AQUI REGRESAMOS*/}
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Cap for Patient</Card.Title>
                                <Card.Text>Disposable, Variety of colors</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img22} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                    <CardDeck>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Card.Title className="color-texto">Specula</Card.Title>
                                        <Card.Text>Disposable in individual packaging</Card.Text>
                                    </Col>
                                    <Col>
                                        <Card.Img variant="top" src={img19} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Card.Title className="color-texto">Specula</Card.Title>
                                        <Card.Text>Disposable in individual packaging</Card.Text>
                                    </Col>
                                    <Col>
                                        <Card.Img variant="top" src={img20} />
                                    </Col>
                                </Row>  
                            </Card.Body>
                        </Card>
                    </CardDeck>
                </Container>
                <br/>
                
                <Pagination className="justify-content-center" size="lg">
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item href="/en/MedicalInput2">{2}</Pagination.Item>
                <Pagination.Item href="/en/MedicalInput3">{3}</Pagination.Item>
                </Pagination>

                <Footer/>
            </div>
        )
    }
}