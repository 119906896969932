import img1 from './Rehabilitacion/img1.jpg'
import img2 from './Rehabilitacion/img2.jpg'
import img25 from './Rehabilitacion/img25.jpg'
import img26 from './Rehabilitacion/img26.jpg'
import img27 from './Rehabilitacion/img27.jpg'
import img29 from './Rehabilitacion/img29.jpg'
import img30 from './Rehabilitacion/img30.jpg'
import img31 from './Rehabilitacion/img31.jpg'
import img32 from './Rehabilitacion/img32.jpg'
import {Footer} from './Footer'
import React, {Component} from 'react'
import { Card, Pagination, CardColumns, Row, Col } from 'react-bootstrap'
import {Navigation} from './Navigation'


export class Rehabilitacion extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Rehabilitación</h1></Col>
                    <Col></Col>
                </Row>
                
                <br/>
                <Pagination className="justify-content-center" size="lg">
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item href="/rehabilitacion2">{2}</Pagination.Item>
                <Pagination.Item href="/rehabilitacion3">{3}</Pagination.Item>
                </Pagination>

                <CardColumns className="text-center">
                    <Card>
                        <Card.Img variant="top" src={img1}/>
                        
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={img2}/>
                        
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={img26}/>
                    </Card>

                    <Card>
                        <Card.Img variant="top" src={img27}/>
                    </Card>
                    
                    <Card>
                        <Card.Img variant="top" src={img29}/>
                    </Card>
                    
                    <Card>
                        <Card.Img variant="top" src={img30}/>
                    </Card>
                    
                    <Card>
                        <Card.Img variant="top" src={img25}/>
                    </Card>
                    
                    <Card>
                        <Card.Img variant="top" src={img31}/>
                    </Card>
                    
                    <Card>
                        <Card.Img variant="top" src={img32}/>
                    </Card>

                </CardColumns>
                <br/>
                
                <Pagination className="justify-content-center" size="lg">
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item href="/rehabilitacion2">{2}</Pagination.Item>
                <Pagination.Item href="/rehabilitacion3">{3}</Pagination.Item>
                </Pagination>
                <Footer/>
            </div>
        )
    }
}