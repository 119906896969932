import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { CardDeck, Card, Pagination, Container, Row, Col } from 'react-bootstrap'
import img42 from '../EquipoMedico/img42.jpg'
import img43 from '../EquipoMedico/img43.jpg'
import img44 from '../EquipoMedico/img44.jpg'
import img45 from '../EquipoMedico/img45.jpg'
import img46 from '../EquipoMedico/img46.jpg'
import img47 from '../EquipoMedico/img47.jpg'
import img48 from '../EquipoMedico/img48.jpg'
import img49 from '../EquipoMedico/img49.jpg'
import img50 from '../EquipoMedico/img50.jpg'
import img51 from '../EquipoMedico/img51.jpg'
import img52 from '../EquipoMedico/img52.jpg'
import img53 from '../EquipoMedico/img53.jpg'
import img54 from '../EquipoMedico/img54.jpg'
import img55 from '../EquipoMedico/img55.jpg'
import img56 from '../EquipoMedico/img56.jpg'
import img57 from '../EquipoMedico/img57.jpg'
import img58 from '../EquipoMedico/img58.jpg'
import img59 from '../EquipoMedico/img59.jpg'

export class MedicalInput3 extends Component{
    render(){
        return(
            <div>
                <Header />
                <Container><br/>
                <Pagination className="justify-content-center" size="lg">
                <Pagination.Item href="/en/MedicalInput">{1}</Pagination.Item>
                <Pagination.Item href="/en/MedicalInput2">{2}</Pagination.Item>
                <Pagination.Item active>{3}</Pagination.Item>
                </Pagination>
                <br/>
                    <CardDeck>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Wooden swabs</Card.Title>
                                    <Card.Text>Box of 100 Units</Card.Text>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img42} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Scalpel Sheets</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img43} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </CardDeck><br/>
                    <CardDeck>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">I.V Catheter</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img44} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">I.V Catheter</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img45} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </CardDeck><br/>
                    <CardDeck>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">I.V Butterfly Catheter</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img46} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">I.V Catheter</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img47} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </CardDeck><br/>
                    <CardDeck>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Disposable insulin syringe</Card.Title>
                                    <Card.Text>Individual packaging</Card.Text>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img48} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Disposable syringe 3cc, 5cc, 10cc</Card.Title>
                                    <Card.Text>Individual packaging</Card.Text>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img49} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </CardDeck><br/>
                    <CardDeck>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Nelaton Catheter</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img50} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Eye patches</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img51} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </CardDeck><br/>
                    <CardDeck>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Foley Catheter</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img52} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Rectal Catheter</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img53} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </CardDeck><br/>
                    <CardDeck>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Chemical sutures</Card.Title>
                                    <Card.Text>Nylon, silk, catgut chromic, catgut simple, polypropylene, polyester braided, polyglycolic acid.</Card.Text>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img54} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Thermometers</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img55} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </CardDeck><br/>
                    <CardDeck>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Endotracheal tubes</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img56} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">-	Endotracheal tubes</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img57} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </CardDeck><br/>
                    <CardDeck>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">-	Endotracheal tubes</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img58} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card>
                        <Card.Header><h5>Disposable products</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Endotracheal tubes</Card.Title>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img59} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </CardDeck><br/>
                </Container>
                <Pagination className="justify-content-center" size="lg">
                <Pagination.Item href="/en/MedicalInput">{1}</Pagination.Item>
                <Pagination.Item href="/en/MedicalInput2">{2}</Pagination.Item>
                <Pagination.Item active>{3}</Pagination.Item>
                </Pagination>
                <Footer />
            </div>
        )
    }
} 