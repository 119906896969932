import React from 'react'
import {Home} from './Components/Home'
import {Contacto} from './Components/Contacto'
import {CadenaFrio} from './Components/CadenaFrio'
import {Clinico} from './Components/Clinico'
import {Insumo} from './Components/Insumo'
import {Encamamiento} from './Components/Encamamiento'
import {Insumo2} from './Components/Insumo2'
import {Insumo3} from './Components/Insumo3'
import {Emergencia} from './Components/Emergencia'
import {EqMedico} from './Components/EqMedico'
import {Instrumental} from './Components/Instrumental'
import {Diagnostico} from './Components/Diagnostico'
import {Diagnostico2} from './Components/Diagnostico2'
import {Rehabilitacion} from './Components/Rehabilitacion'
import {Rehabilitacion2} from './Components/Rehabilitacion2'
import {Rehabilitacion3} from './Components/Rehabilitacion3'
import {Operaciones} from './Components/Operaciones'
import {Auxilios} from './Components/Auxilios'
import {Intensivo} from './Components/Intensivo'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import { Ginecologia } from './Components/Ginecologia'
import { Neonatologia } from './Components/Neonatologia'
import { Talla } from './Components/Talla'
import { Generico } from './Components/Generico'
import { Comerciales } from './Components/Comerciales'
import { EngHome } from './Components/eng/EngHome'
import { ColdChain } from './Components/eng/ColdChain'
import { EngInstrumental } from './Components/eng/EngInstrumental'
import { MedicalInput } from './Components/eng/MedicalInput'
import { MedicalInput2 } from './Components/eng/MedicalInput2'
import { MedicalInput3 } from './Components/eng/MedicalInput3'
import { MedCommercial } from './Components/eng/MedCommercial'
import { MedGeneric } from './Components/eng/MedGeneric'
import { FeClinical } from './Components/eng/FeClinical'
import { FeDiagnosis } from './Components/eng/FeDiagnosis'
import { FeDiagnosis2 } from './Components/eng/FeDiagnosis2'
import { Emergency } from './Components/eng/Emergency'
import { Bedridden } from './Components/eng/Bedridden'
import { Gynecology } from './Components/eng/Gynecology'
import { Intensive } from './Components/eng/Intensive'
import { MedEducational } from './Components/eng/MedEducational'
import { Neonatology } from './Components/eng/Neonatology'
import { Weight } from './Components/eng/Weight'
import { Rehabilitation } from './Components/eng/Rehabilitation'
import { Rehabilitation2 } from './Components/eng/Rehabilitation2'
import { Rehabilitation3 } from './Components/eng/Rehabilitation3'
import { Operations } from './Components/eng/Operations'
import { FirstAids } from './Components/eng/FirstAids'
import { ContactUs } from './Components/eng/ContactUs'

function App(){
    return(
    <BrowserRouter>
            <Switch>
                <Route path='/' component={Home} exact />
                <Route path='/contacto' component={Contacto} />
                <Route path='/instrumental' component={Instrumental} />
                <Route path='/diagnostico' component={Diagnostico} />
                <Route path='/diagnostico2' component={Diagnostico2} />
                <Route path='/insumo' component={Insumo} />
                <Route path='/insumo2' component={Insumo2} />
                <Route path='/insumo3' component={Insumo3} />
                <Route path='/equipoMedico' component={EqMedico} />
                <Route path='/rehabilitacion' component={Rehabilitacion} />
                <Route path='/rehabilitacion2' component={Rehabilitacion2} />
                <Route path='/rehabilitacion3' component={Rehabilitacion3} />
                <Route path='/encamamiento' component={Encamamiento} />
                <Route path='/cadenaFrio' component={CadenaFrio} />
                <Route path='/clinico' component={Clinico} />
                <Route path='/emergencia' component={Emergencia} />
                <Route path='/operaciones' component={Operaciones} />
                <Route path='/auxilios' component={Auxilios} />
                <Route path='/intensivo' component={Intensivo} />
                <Route path='/ginecologia' component={Ginecologia} />
                <Route path='/neonatologia' component={Neonatologia} />
                <Route path='/talla' component={Talla} />
                <Route path='/generico' component={Generico} />
                <Route path='/comerciales' component={Comerciales} />
                <Route path='/en/Home' component={EngHome} />
                <Route path='/en/ColdChain' component={ColdChain} />
                <Route path='/en/Instrumental' component={EngInstrumental} />
                <Route path='/en/MedicalInput' component={MedicalInput} />
                <Route path='/en/MedicalInput2' component={MedicalInput2} />
                <Route path='/en/MedicalInput3' component={MedicalInput3} />
                <Route path='/en/MedCommercial' component={MedCommercial} />
                <Route path='/en/MedGeneric' component={MedGeneric} />
                <Route path='/en/FeClinical' component={FeClinical} />
                <Route path='/en/FeDiagnosis' component={FeDiagnosis} />
                <Route path='/en/FeDiagnosis2' component={FeDiagnosis2} />
                <Route path='/en/Emergency' component={Emergency} />
                <Route path='/en/Bedridden' component={Bedridden} />
                <Route path='/en/Gynecology' component={Gynecology} />
                <Route path='/en/Intensive' component={Intensive} />
                <Route path='/en/MedEducational' component={MedEducational} />
                <Route path='/en/Neonatology' component={Neonatology} />
                <Route path='/en/Weight' component={Weight} />
                <Route path='/en/Rehabilitation' component={Rehabilitation} />
                <Route path='/en/Rehabilitation2' component={Rehabilitation2} />
                <Route path='/en/Rehabilitation3' component={Rehabilitation3} />
                <Route path='/en/Operations' component={Operations} />
                <Route path='/en/FirstAids' component={FirstAids} />
                <Route path='/en/ContactUs' component={ContactUs} />
            </Switch>
        </BrowserRouter>
    )
}

export default App