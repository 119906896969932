import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Container, Form, Row, Col, Button, Modal } from 'react-bootstrap'
import emailjs from 'emailjs-com'
import Recaptcha from 'react-recaptcha'

export class ContactUs extends Component{
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            recaptchaLoad: false,
            isVerified: false
        };
        this.ocultar = this.ocultar.bind(this)
        this.mostrar = this.mostrar.bind(this)
        this.sendEmail = this.sendEmail.bind(this)
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.verifiedRecaptcha = this.verifiedRecaptcha.bind(this);
    }
    ocultar(){
        this.setState({show:false})
    }
    
    mostrar(){
        this.setState({show:true})
    }

    recaptchaLoaded() {
        this.setState({ recaptchaLoad: true });
    }
    
    verifiedRecaptcha(response) {
        if (response) {
            this.setState({ isVerified: true });
        }
    }

    sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm('service_c6wbtzt', 'template_87pr9kp', e.target, 'user_C6smEMFMth386EZ6bxbcx')
        .then((result) => {
            console.log(result.text);
            this.setState({show:true});
            
        }, (error) => {
            console.log(error.text);
        });
    }

    render(){

        return(
            <div>
            <Header/>
                <br/>
            <h1 className="text-center color-texto">Send us an email</h1><br></br>
                <Container className="border border-dark fondo-texto">
                <br/>
                <Form onSubmit={this.sendEmail} >
                    <h2>Contact information</h2>
                    <br/>
                <Form.Group as={Row}>
                <Form.Label column sm={2}>
                    Company
                    </Form.Label>
                    <Col sm={10}>
                    <Form.Control type="text" placeholder="Enter your company" name="from_empresa" required/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                    Name
                    </Form.Label>
                    <Col sm={10}>
                    <Form.Control type="text" placeholder="Enter your name" name="from_name" required/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>
                    Email
                    </Form.Label>
                    <Col sm={10}>
                    <Form.Control type="email" placeholder="Enter your e-mail" name="contact_email" required/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>
                    Cell phone 
                    </Form.Label>
                    <Col sm={10}>
                    <Form.Control type="tel" placeholder="enter your cell phone" name="telefono" required/>
                    </Col>
                </Form.Group>
                <hr></hr>
                <fieldset>
                    <br></br>
                <h2>Information about products you want to quote</h2>
                <br/>
                    <Form.Group as={Row}>
                    <Form.Label as="legend" column sm={2}>
                    Categories
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Check
                        type="checkbox"
                        label="Medical equipment"
                        id="formHorizontalRadios1"
                        name="equipoMedico"
                        />
                        <Form.Check
                        type="checkbox"
                        label="Rehabilitation equipment"
                        id="formHorizontalRadios2"
                        name="rehabilitacion"
                        />
                        <Form.Check
                        type="checkbox"
                        label="Instrumental"
                        id="formHorizontalRadios3"
                        name="instrumental"
                        />
                        <Form.Check
                        type="checkbox"
                        label="Diagnosis"
                        id="formHorizontalRadios4"
                        name="diagnostico"
                        />
                        <Form.Check
                        type="checkbox"
                        label="Medical input"
                        id="formHorizontalRadios5"
                        name="insumo"
                        />
                        <Form.Check
                        type="checkbox"
                        label="Medicine"
                        id="formHorizontalRadios6"
                        name="medicamento"
                        />
                    </Col>
                    </Form.Group>
                </fieldset>
                <Form.Group controlId="exampleForm.ControlTextarea1" as={Row}>
                    <Form.Label className="text-justify" column sm={2}>Detail the list of products and quantity to consult:</Form.Label>
                    <Col sm={10}>
                    <Form.Control as="textarea" rows={5} name="message" required/>
                    </Col>
                </Form.Group>
                <Row>
                    <Col sm={2}>
                    </Col>
                    <Col sm={10}>
                       <Recaptcha
                        sitekey="6LeD59MaAAAAAIWNbk6B_MT-IDf6P0GbgwURUXAB"
                        render="explicit"
                        onloadCallback={this.recaptchaLoaded}
                        verifyCallback={this.verifiedRecaptcha}
                        /> 
                    </Col>
                </Row>
                <Form.Group as={Row}>
                    <Col sm={{ span: 10, offset: 2 }}>
                    <Button type="submit" variant="primary">Send</Button>
                    </Col>
                </Form.Group>
                </Form>
                </Container>
                <br/>
                <Footer/>
                <Modal show={this.state.show} onHide={this.ocultar} animation={false}>
                    <Modal.Header closeButton>
                    <Modal.Title>Mail sent successfully</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>We will get in contact as soon as possible</Modal.Body>
                </Modal>
            </div>
        )
    }
}