import React, {Component} from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export class Footer extends Component{
    render(){
        return(
            <div className="bg-primary">
                <Container>
               <br></br>  
                <Row className="text-white">
                    <Col>
                    <h4><strong>Address:</strong></h4>
                    </Col>
                    <Col>
                    <h4><strong>Phone: </strong></h4>
                    </Col>
                    <Col>
                    <h4><strong>For more prices and quotations send an e-mail to:</strong></h4>
                    </Col>
                </Row>
                <br></br>
                <Row className="text-white">
                    <Col>8a. Calle 17-19, Zona 15 Vista Hermosa I Colonia El Maestro II, Guatemala Guatemala City, 01015</Col>
                    <Col>(+502) 2201-1222</Col>
                    <Col>
                    <a href = "mailto: info@drycominternational.com" className="text-white">info@drycominternational.com</a>
                    <br></br>
                    <a href = "mailto: ventas@drycominternational.com" className="text-white">ventas@drycominternational.com</a>
                    </Col>
                </Row>
                <br></br>
                </Container>
            </div>
           
        )
    }
}