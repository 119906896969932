import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Card, CardColumns, Row, Col } from 'react-bootstrap'
import img70 from '../EquipoMedico/img70.jpg'

export class Intensive extends Component{
    render(){
        return(
            <div>
                <Header/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Intensive</h1></Col>
                    <Col></Col>
                </Row> 
                <br/>
                <CardColumns className="text-center">
                <Card>
                    <Card.Img variant="top" src={img70} />
                    <Card.Body>
                        <Card.Title>Intensive care</Card.Title>
                    </Card.Body>
                </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}