import img70 from './EquipoMedico/img70.jpg'
import {Footer} from './Footer'
import React, {Component} from 'react'
import { Card, CardColumns, Row, Col } from 'react-bootstrap'
import {Navigation} from './Navigation'

export class Intensivo extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Intensivo</h1></Col>
                    <Col></Col>
                </Row> 
                <br/>
                <CardColumns className="text-center">
                <Card>
                    <Card.Img variant="top" src={img70} />
                    <Card.Body>
                        <Card.Title>Cuidados intensivos</Card.Title>
                    </Card.Body>
                </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}