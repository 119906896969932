import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Card, CardColumns, Row, Col } from 'react-bootstrap'
import img67 from '../EquipoMedico/img67.jpg'
import img68 from '../EquipoMedico/img68.jpg'

export class Operations extends Component{
    render(){
        return(
            <div>
                <Header/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Operations room</h1></Col>
                    <Col></Col>
                </Row><br/>
                <CardColumns className="text-center">
                    <Card>
                        
                        <Card.Img variant="top" src={img67} />
                        <Card.Body>
                            <Card.Title>Operations room I</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card>
                        
                        <Card.Img variant="top" src={img68} />
                        <Card.Body>
                            <Card.Title>Operations room II</Card.Title>
                        </Card.Body>
                    </Card>
                    
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}