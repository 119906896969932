import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Card, CardColumns, Row, Col } from 'react-bootstrap'
import img66 from '../EquipoMedico/img66.jpg'

export class Gynecology extends Component{
    render(){
        return(
            <div>
                <Header/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Gynecology</h1></Col>
                    <Col></Col>
                </Row> 
                <br/>
                <CardColumns className="text-center">
                    <Card>
                        <Card.Img variant="top" src={img66} />
                        <Card.Body>
                            <Card.Title>Gynecology</Card.Title>
                        </Card.Body>
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}