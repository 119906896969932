import {Footer} from './Footer'
import React, {Component} from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import {Navigation} from './Navigation'

export class Generico extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <Container className="fondo-texto">
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Generico</h1></Col>
                    <Col></Col>
                </Row>
                <br/>
                <p>
                    <strong>POR MAYOR.</strong> Tabletas, Fco. Jarabe Suspension, Vial
                </p>
                <br></br>
                <Row>
                    <Col>
                    <ul>
                        <li>Acetaminofén</li>
                        <li>Aciclovir</li>
                        <li>Ácido Acetilsalicilico de 500 mg</li>
                        <li>Ácido fólico</li>
                        <li>Acido Valproico</li>
                        <li>Agua estéril</li>
                        <li>Albendazol</li>
                        <li>Alka Seltzer </li>
                        <li>Alopurinol</li>
                        <li>Alquitrán de hulla+ acido salicílico</li>
                        <li>Ambroxol</li>
                        <li>Amikacina</li>
                        <li>Amoxicilina + Acido Clavulanico</li>
                        <li>Amoxicilina</li>
                        <li>Ampicilina</li>
                        <li>Aspirinas</li>
                        <li>Azitromicina</li>
                        <li>Azufre y petrolato blanco</li>
                        <li>Bacillus clausil</li>
                        <li>Benzetacil</li>
                        <li>Benzoilo peróxido</li>
                        <li>Betametasona + acido salicílico</li>
                        <li>Betametasona valerato</li>
                        <li>Bicarbonato de sodio</li>
                        <li>Biperideno clorhidrato</li>
                        <li>Bromhidrato de Fenoterol</li>
                        <li>Bromuro</li>
                        <li>Bromuro de Ipratropio</li>
                        <li>Bromuro de Otilonio</li>
                        <li>Bromuro de Vecuronio</li>
                        <li>Budesónida</li>
                        <li>Carbamazepina</li>
                        <li>Caspofungina</li>
                        <li>Cefadroxilo</li>
                        <li>Cefalotina</li>
                        <li>Cefepima</li>
                        <li>Cefixima</li>
                        <li>Cefotaxima</li>
                        <li>Ceftazidima</li>
                        <li>Ceftriaxona</li>
                        <li>Ciclosporina microemulsion</li>
                        <li>Ciprofloxacina</li>
                        <li>Claritromicina</li>
                        <li>Clindamicina</li>
                        <li>Clobetasol propionato</li>
                        <li>Cloranfenicol</li>
                        <li>Clorfeniramina maleato</li>
                        <li>Clorhidrato de Metformina</li>
                        <li>Cloroquina fosfato</li>
                        <li>Cloruro de potasio</li>
                        <li>Cloruro de sodio</li>
                        <li>Clotrimazol</li>
                        <li>Colchicina</li>
                        <li>Complejo B </li>
                    </ul>
                    </Col>
                    <Col>
                    <ul>
                        <li>Dexketoprofeno trometamol</li>
                        <li>Dextrosa solución</li>
                        <li>Dextrosa y Cloruro de sodio</li>
                        <li>Diclofenaco</li>
                        <li>Dicloxaciclina</li>
                        <li>Difenidol</li>
                        <li>Dimenhidrinato</li>
                        <li>Dipropionato de Beclometasona</li>
                        <li>Dorival</li>
                        <li>Doxiciclina</li>
                        <li>Epinefrina</li>
                        <li>Ergotamina en asociación</li>
                        <li>Eritromicina</li>
                        <li>Esomeprazol</li>
                        <li>Estreptomicina sulfato</li>
                        <li>Fenitoina sódica</li>
                        <li>Fluconazol</li>
                        <li>Gentamicina</li>
                        <li>Glimepirida</li>
                        <li>Hidrocortisona</li>
                        <li>Hidroxicloroquina sulfato</li>
                        <li>Hidróxido de aluminio, Magnesio</li>
                        <li>Hierro aminoquelado + ácido fólico</li>
                        <li>Hierro sacarato o citrato</li>
                        <li>Hioscina</li>
                        <li>Ibuprofen</li>
                        <li>Ipatropio</li>
                        <li>Itraconazol</li>
                        <li>Ketoconazol</li>
                        <li>Ketoprofeno</li>
                        <li>Lactobacillus Reuteri Protectis</li>
                        <li>Lactulosa</li>
                        <li>Lanzoprazol</li>
                        <li>Leflunomida</li>
                        <li>Levetiracetam</li>
                        <li>Levofloxacina</li>
                        <li>Lidocaína con epinefrina</li>
                        <li>Magnesio sulfato</li>
                        <li>Mamotrigina</li>
                        <li>Metamizol Sódico</li>
                        <li>Metronidazol</li>
                        <li>Metronidazol + Nistatina</li>
                        <li>Moxifloxacino</li>
                        <li>Multivitaminas</li>
                        <li>Multivitaminas para infusión</li>
                        <li>Neomicina</li>
                        <li>Nifedipino</li>
                        <li>Nistatina</li>
                        <li>Nitrato de plata</li>
                        <li>Nitrofurantoina</li>
                        <li>Ofloxacina</li>
                        <li>Oxcarbazepina</li>
                        <li>Oxico de Zinc</li>
                    </ul>
                    </Col>
                    <Col>
                    <ul>
                        <li>Oxido de Zinc + Calamina</li>
                        <li>Pamoato de Pirantel</li>
                        <li>Pancrealipasa</li>
                        <li>Penicilina G benzatínica</li>
                        <li>Penicilina G procaína</li>
                        <li>Penicilina G sódica Cristalina</li>
                        <li>Penicilinas, entre otros</li>
                        <li>Permetrina</li>
                        <li>Phyllium plantago</li>
                        <li>Piperacina / Tazobactam</li>
                        <li>Pirimetamina / Sulfadoxina</li>
                        <li>Polimixina B</li>
                        <li>Prednicarbato</li>
                        <li>Prednisolona</li>
                        <li>Pregabalina</li>
                        <li>Prenatales</li>
                        <li>Primaquina fosfato o difosfato</li>
                        <li>Propinoxato</li>
                        <li>Ranitidina clorhidrato</li>
                        <li>Ranitidina</li>
                        <li>Riluzol</li>
                        <li>Rupatadina fumarato</li>
                        <li>Salbutamol</li>
                        <li>Sales de Rehidratación Oral</li>
                        <li>Secnidazol</li>
                        <li>Simeticona</li>
                        <li>Simitriptan</li>
                        <li>Solución Hartman</li>
                        <li>Solución pediátrica No. 1</li>
                        <li>Solución pediátrica No. 2</li>
                        <li>Solución Salina</li>
                        <li>Subsalicilato de bismuto</li>
                        <li>Sucralfato</li>
                        <li>Suero Antiofídico polivalente</li>
                        <li>Suero oral liquido</li>
                        <li>Suplemento de Micronutrientes</li>
                        <li>Teclozan</li>
                        <li>Tifeciclina</li>
                        <li>Timolol maleato</li>
                        <li>Tinidazol</li>
                        <li>Tobramicina</li>
                        <li>Tobramicina / Dexametasona</li>
                        <li>Topiramato</li>
                        <li>Tramadol Clorhidrato</li>
                        <li>Trimetroprim – Sulfametoxazol</li>
                        <li>Vitamina “A” perla de 50,000 UI</li>
                        <li>Vitamina A</li>
                        <li>Vitamina B 12</li>
                        <li>Vitamina B1</li>
                        <li>Vitamina C</li>
                        <li>Vitamina D</li>
                        <li>Vitamina K</li>
                        <li>Vitaminas Neurotropas</li>
                    </ul>
                    </Col>
                </Row>
                </Container>
                <Footer/>
            </div>
        )
    }
}