import React, {Component} from 'react'
import { Row, Col, Container, Card, CardColumns, Accordion, Button } from 'react-bootstrap'
import { Header } from './Header'
import { Footer } from './Footer'
import img1 from '../Instrumental/img1.jpg'
import img2 from '../Instrumental/img2.jpg'
import img3 from '../Instrumental/img3.jpg'
import img4 from '../Instrumental/img4.jpg'
import img5 from '../Instrumental/img5.jpg'
import img6 from '../Instrumental/img6.jpg'
import img7 from '../Instrumental/img7.jpg'
import img8 from '../Instrumental/img8.jpg'
import img9 from '../Instrumental/img9.jpg'
import img10 from '../Instrumental/img10.jpg'
import img11 from '../Instrumental/img11.jpg'
import img12 from '../Instrumental/img12.jpg'
import img13 from '../Instrumental/img13.jpg'
import img14 from '../Instrumental/img14.jpg'
import img15 from '../Instrumental/img15.jpg'
import img16 from '../Instrumental/img16.jpg'
import img17 from '../Instrumental/img17.jpg'

export class EngInstrumental extends Component{
    render(){
        return(
            <div>
                <Header/>
                <Container>
                    <h1 className="text-center">Instrumental</h1>
                    <p>
                    If you need to buy medical equipment, make sure that your equipment meets the quality you are looking for.<br/>
                    Place your order online. And remember to confirm your medication order with us.<br/>
                    Did not find any equipment you need? Contact us! It will be a pleasure to support you.<br/>
                    Different qualities of Stainless Steel: German, USA, Pakistani and China
                    </p>
                <br></br>
                <Accordion>
                <Card>
                    <Accordion.Toggle as={Button} variant="info" eventKey="0" className="dropdown-toggle">
                    Instrumental of stainless steel
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body className="fondo-texto">
                        <Row>
                        <Col>
                    <ul>
                        <li>Bacín</li>
                        <li>Bandejas</li>
                        <li>Copas para medicamentos</li>
                        <li>Cucharillas para Legrado</li>
                        <li>Dilatadores</li>
                        <li>Equipos para Cesárea</li>
                        <li>Equipos para parto</li>
                        <li>Espéculos Vaginales</li>
                        <li>Esterilizadores</li>
                        <li>Estuches de Cirugía Menor y Disección</li>
                        <li>kits de cirugía mayor y menor</li>
                        <li>Laringoscopios</li>
                    </ul>
                    </Col>
                    <Col>
                    <ul>
                        <li>Martillo de reflejos</li>
                        <li>Ollas Autoclaves</li>
                        <li>Otorrinolaringoscopios</li>
                        <li>Palanganas</li>
                        <li>Pinzas</li>
                        <li>Porta Pinzas</li>
                        <li>Porta Termómetros</li>
                        <li>Riñones</li>
                        <li>Set de Diagnostico</li>
                        <li>Tenáculos</li>
                        <li>Tijeras</li>
                        <li>Urinales</li>
                    </ul>
                    </Col>
                    </Row>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Accordion.Collapse eventKey="0">
                    <CardColumns className="border border-info fondo-texto text-center">
                    <Card style={{ width: '21rem' }}>
                    <Card.Img variant="top" src={img2}/>
                    <Card.Body>
                        <Card.Title>Especulos vaginales</Card.Title>
                    </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                    <Card.Img variant="top" src={img3}/>
                    <Card.Body>
                        <Card.Title>Bandeja con tapadera</Card.Title>
                    </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                    <Card.Img variant="top" src={img4}/>
                    <Card.Body>
                        <Card.Title>Porta pinzas</Card.Title>
                    </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                        <Card.Img variant="top" src={img5}/>
                        <Card.Body>
                            <Card.Title>Kit de cirugía pequeña</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                        <Card.Img variant="top" src={img6}/>
                        <Card.Body>
                            <Card.Title>Kits de acero inoxidable</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                        <Card.Img variant="top" src={img7}/>
                        <Card.Body>
                            <Card.Title>Palanganas de acero inoxidable</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                    <Card.Img variant="top" src={img8}/>
                    <Card.Body>
                        <Card.Title>Palangana de acero inoxidable</Card.Title>
                    </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                    <Card.Img variant="top" src={img13}/>
                    <Card.Body>
                        <Card.Title>Riñones de acero inoxidable</Card.Title>
                    </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                        <Card.Img variant="top" src={img14}/>
                        <Card.Body>
                            <Card.Title>Tijeras</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                    <Card.Img variant="top" src={img15}/>
                    <Card.Body>
                        <Card.Title>Tijeras</Card.Title>
                    </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                        <Card.Img variant="top" src={img16}/>
                        <Card.Body>
                            <Card.Title>Tijeras</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                        <Card.Img variant="top" src={img17}/>
                        <Card.Body>
                            <Card.Title>Tijeras</Card.Title>
                        </Card.Body>
                    </Card>
                    </CardColumns>
                </Accordion.Collapse>
                <Card>
                    <Accordion.Toggle as={Button} variant="info" eventKey="1" className="dropdown-toggle">
                    Pinzas de disección
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                    <Card.Body className="fondo-texto">
                    <Row>
                    <Col>
                    <ul>
                        <li>Pinza Cushing</li>
                        <li>Pinza Potts – Smith</li>
                        <li>Pinza Rusa</li>
                        <li>Pinza Semkem</li>
                        <li>Pinzas Adson</li>
                    </ul>
                    </Col>
                    </Row>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Button} variant="info" eventKey="2" className="dropdown-toggle">
                    Pinzas hemostáticas
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                    <Card.Body className="fondo-texto">
                    <Row>
                <Col>
                    <ul>
                        <li>Pinza Aro</li>
                        <li>Pinza Backhaus</li>
                        <li>Pinza Bertola</li>
                        <li>Pinza Bozeman</li>
                        <li>Pinza Chapul</li>
                        <li>Pinza ClampsCrafford</li>
                        <li>Pinza ClampsWerthein</li>
                        <li>Pinza Crile</li>
                        <li>Pinza Crile Rankin</li>
                        <li>Pinza Dandy para cuero cabelludo</li>
                        <li>Pinza Desjardin para cístico</li>
                        <li>Pinza Doyen</li>
                        <li>Pinza Faure para arteria uterina</li>
                        <li>Pinza Foerster</li>
                        <li>Pinza Gregoire</li>
                        <li>Pinza Guyon</li>
                    </ul>
                    </Col>
                    <Col>
                        <ul>
                            <li>Pinza Halstead</li>
                            <li>Pinza HarringthonLovel doble útil</li>
                            <li>Pinza Heaney</li>
                            <li>Pinza Heiss</li>
                            <li>Pinza Kell-Rankin</li>
                            <li>Pinza Kelly</li>
                            <li>Pinza Kocher</li>
                            <li>Pinza Lahey</li>
                            <li>Pinza Maier uterina</li>
                            <li>Pinza Martel</li>
                            <li>Pinza Mister</li>
                            <li>Pinza Pean</li>
                            <li>Pinza Pozzi</li>
                            <li>Pinza Rochester Pean</li>
                            <li>Pinza Schade</li>
                            <li>Pinza Schnidt</li>
                        </ul>
                    </Col>
                    </Row>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Accordion.Collapse eventKey="2">
                    <CardColumns className="border border-info fondo-texto text-center">
                    <Card style={{ width: '21rem' }}>
                    <Card.Img variant="top" src={img1}/>
                    <Card.Body>
                        <Card.Title className="text-center">Pinzas</Card.Title>
                    </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                    <Card.Img variant="top" src={img9}/>
                    <Card.Body>
                        <Card.Title>Pinzas</Card.Title>
                    </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                    <Card.Img variant="top" src={img10}/>
                    <Card.Body>
                        <Card.Title>Pinzas</Card.Title>
                    </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                        <Card.Img variant="top" src={img11}/>
                        <Card.Body>
                            <Card.Title>Pinzas</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '21rem' }}>
                        <Card.Img variant="top" src={img12}/>
                        <Card.Body>
                            <Card.Title>Pinzas</Card.Title>
                        </Card.Body>
                    </Card>
                    </CardColumns>
                </Accordion.Collapse>
               
                <Card>
                    <Accordion.Toggle as={Button} variant="info" eventKey="3" className="dropdown-toggle">
                    Separadores
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                    <Card.Body className="fondo-texto">
                    <Row>
                    <Col>
                    <ul>
                        <li>Separador Balfour</li>
                        <li>Separador Blefaróstato</li>
                        <li>Separador Caeiro</li>
                        <li>Separador Cushing para venas</li>
                        <li>Separador Deaver</li>
                        <li>Separador Ferabeuff</li>
                        <li>Separador Finochietto</li>
                        <li>Separador Gelpi</li>
                        <li>Separador Gosset</li>
                        <li>Separador Jansen</li>
                        <li>Separador Langenbeck</li>
                    </ul> 
                    </Col>
                    <Col>
                        <ul>
                        <li>Separador Love para nervios</li>
                        <li>Separador Millin</li>
                        <li>Separador Millin de alambre</li>
                        <li>Separador Richardson</li>
                        <li>Separador Roux</li>
                        <li>Separador Senn-Mueller</li>
                        <li>Separador Weithlaner</li>
                        <li>Separador Wolkmann</li>
                        <li>Separadores Adson</li>
                        <li>Valva Doyen</li>
                    </ul>
                    </Col>
                    </Row>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Button} variant="info" eventKey="4" className="dropdown-toggle">
                    Instrumental para sutura
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                    <Card.Body className="fondo-texto">
                    <Row>
                    <Col>
                    <ul>
                        <li>Pinza porta agujas Bozeman</li>
                        <li>Pinza porta agujas de bakey</li>
                        <li>pinza porta agujas Halsey</li>
                        <li>Pinza porta agujas Masson</li>
                        <li>Pinza Porta agujas Ryder</li>
                        <li>Pinza porta agujas Tijera Olsen – Hegar</li>
                        <li>Porta agujas finochietto</li>
                        <li>porta agujas hegar</li>
                        <li>Porta agujas Mathieu</li>
                        <li>Porta agujas Mayo – Hegar</li>
                    </ul>
                    </Col>
                    </Row>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Button} variant="info" eventKey="5" className="dropdown-toggle">
                    Instrumental de corte, tijeras
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="5">
                    <Card.Body className="fondo-texto">
                    <Row>
                    <Col>
                    <ul>
                        <li>Tijera Curac</li>
                        <li>Tijera doyen</li>
                        <li>Tijera Iris</li>
                        <li>Tijera Kelly</li>
                        <li>Tijera Lister</li>
                        <li>Tijera Mayo</li>
                        <li>Tijera Metzenbaum</li>
                        <li>Tijera Potts Smith</li>
                        <li>Tijera Schmieden-Taylor</li>
                        <li>Tijera Sims</li>
                        <li>Tijera Spencer</li>
                        <li>Tijera Strully p/Neurocirugia</li>
                    </ul>
                    </Col>
                    </Row>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Button} variant="info" eventKey="6" className="dropdown-toggle">
                    Instrumental de corte, bisturi
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="6">
                    <Card.Body className="fondo-texto">
                    <Row>
                    <Col>
                    <ul>
                        <li>Bisturi Collin</li>
                        <li>Bisturi Mango No. 3</li>
                        <li>Bisturi Mango No. 4</li>
                        <li>Bisturi Mango No. 7</li>
                    </ul>
                    </Col>
                    </Row>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Button} variant="info" eventKey="7" className="dropdown-toggle">
                    Otros Instrumentos
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="7">
                    <Card.Body className="fondo-texto">
                    <Row>
                    <Col>
                    <ul>
                        <li>Disector Doble Hurd</li>
                        <li>Legra Farabeuff</li>
                        <li>Pinza CostotomoColin</li>
                        <li>Sonda Acanalada</li>
                        <li>Trepano Hudson – Trepano Doyen</li>                        
                    </ul>
                    </Col>
                    </Row>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>
                <br></br>
                </Container>

                <Footer/>
            </div>
        )
    }
}