import img61 from './EquipoMedico/img61.jpg'
import img62 from './EquipoMedico/img62.jpg'
import {Footer} from './Footer'
import React, {Component} from 'react'
import { Card, CardColumns, Row, Col } from 'react-bootstrap'
import {Navigation} from './Navigation'

export class Emergencia extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <br></br>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1 >Emergencia</h1></Col>
                    <Col></Col>
                </Row>
                <br></br>
                <CardColumns className="text-center">
                    
                    <Card>
                        <Card.Img variant="top" src={img61} />
                        <Card.Body>
                            <Card.Title>Salas de emergencia I</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={img62} />
                        <Card.Body>
                            <Card.Title>Salas de emergencia II</Card.Title>
                        </Card.Body>
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}