import img71 from './EquipoMedico/img71.jpg'
import img72 from './EquipoMedico/img72.jpg'
import img73 from './EquipoMedico/img73.jpg'
import {Footer} from './Footer'
import React, {Component} from 'react'
import { Card, CardColumns, Col, Row } from 'react-bootstrap'
import {Navigation} from './Navigation'

export class Auxilios extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Primeros Auxilios</h1></Col>
                    <Col></Col>
                </Row><br/>
                <CardColumns className="text-center">
                <Card>
                    <Card.Img variant="top" src={img71} />
                    <Card.Body>
                        <Card.Title>Maletines y mochilas</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img72} />
                    <Card.Body>
                        <Card.Title>Accesorios</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img73} />
                    <Card.Body>
                        <Card.Title>Otros accesorios</Card.Title>
                    </Card.Body>
                </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}