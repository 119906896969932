import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Card, CardColumns, Pagination, Row, Col } from 'react-bootstrap'
import img8 from '../Diagnostico/img8.jpg'
import img9 from '../Diagnostico/img9.jpg'
import img10 from '../Diagnostico/img10.jpg'
import img11 from '../Diagnostico/img11.jpg'
import img12 from '../Diagnostico/img12.jpg'
import img13 from '../Diagnostico/img13.jpg'
import img14 from '../Diagnostico/img14.jpg'
import img15 from '../Diagnostico/img15.jpg'
import img16 from '../Diagnostico/img16.jpg'
import img17 from '../Diagnostico/img17.jpg'
import img18 from '../Diagnostico/img18.jpg'
import img24 from '../Diagnostico/img24.jpg'


export class FeDiagnosis extends Component{
    render(){
        return(
            <div>
                <Header/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Diagnosis</h1></Col>
                    <Col></Col>
                </Row> 
            <br/>
            <Pagination className="justify-content-center" size="lg">
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item href="/en/FeDiagnosis2">{2}</Pagination.Item>
            </Pagination>
            <CardColumns className="text-center">
                <Card >
                    <Card.Img variant="top" src={img24}/>
                    <Card.Body>
                        <Card.Title>Accessories for stethoscopes</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img10}/>
                    <Card.Body>
                        <Card.Title>Aneroid sphygmomanometer</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img11}/>
                    <Card.Body>
                        <Card.Title>Aneroid sphygmomanometer </Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img12}/>
                    <Card.Body>
                        <Card.Title>Aneroid sphygmomanometer </Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img13}/>
                    <Card.Body>
                        <Card.Title>Aneroid sphygmomanometer</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img14}/>
                    <Card.Body>
                        <Card.Title>Aneroid sphygmomanometer</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img15}/>
                    <Card.Body>
                        <Card.Title>Aneroid sphygmomanometer</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img8}/>
                    <Card.Body>
                        <Card.Title>Mercury sphygmomanometer</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img17}/>
                    <Card.Body>
                        <Card.Title>Aneroid sphygmomanometer</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img18}/>
                    <Card.Body>
                        <Card.Title>Aneroid sphygmomanometer</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img16}/>
                    <Card.Body>
                        <Card.Title>Aneroid sphygmomanometer</Card.Title>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Img variant="top" src={img9}/>
                    <Card.Body>
                        <Card.Title>Pedestal sphygmomanometer</Card.Title>
                    </Card.Body>
                </Card>
            </CardColumns>
            <Pagination className="justify-content-center" size="lg">
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item href="/en/FeDiagnosis2">{2}</Pagination.Item>
            </Pagination>
                <Footer/>
            </div>
        )
    }
}