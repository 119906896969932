import {Footer} from './Footer'
import React, {Component} from 'react'
import { CardColumns, Row, Col, Card } from 'react-bootstrap'
import Maniquis1 from './Educativo/Maniquis1.jpg'
import Maniqui2 from './Educativo/Maniqui2.jpg'
import {Navigation} from './Navigation'

export class EqMedico extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Medico/Educativo</h1></Col>
                    <Col></Col>
                </Row>
                <br/>
                <CardColumns className="text-center">
                    <Card >
                        <Card.Img variant="top" src={Maniquis1}/>
                        <Card.Body>
                            <Card.Title>Maniquíes educativos</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card >
                        <Card.Img variant="top" src={Maniqui2}/>
                        <Card.Body>
                            <Card.Title>Maniqui Simulador de parto</Card.Title>
                        </Card.Body>
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}