import img66 from './EquipoMedico/img66.jpg'
import {Footer} from './Footer'
import React, {Component} from 'react'
import { Card, CardColumns, Row, Col } from 'react-bootstrap'
import {Navigation} from './Navigation'

export class Ginecologia extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Ginecología</h1></Col>
                    <Col></Col>
                </Row> 
                <br/>
                <CardColumns className="text-center">
                    <Card>
                        <Card.Img variant="top" src={img66} />
                        <Card.Body>
                            <Card.Title>Ginecologia</Card.Title>
                        </Card.Body>
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}