import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Card, CardColumns, Row, Col } from 'react-bootstrap'
import img61 from '../EquipoMedico/img61.jpg'
import img62 from '../EquipoMedico/img62.jpg'

export class Emergency extends Component{
    render(){
        return(
            <div>
                <Header/>
                <br></br>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1 >Emergency</h1></Col>
                    <Col></Col>
                </Row>
                <br></br>
                <CardColumns className="text-center">
                    
                    <Card>
                        <Card.Img variant="top" src={img61} />
                        <Card.Body>
                            <Card.Title>Emergency rooms I</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={img62} />
                        <Card.Body>
                            <Card.Title>Emergency rooms II</Card.Title>
                        </Card.Body>
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}