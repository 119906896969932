import React, {Component} from 'react'
import {Navbar, Nav, NavDropdown} from 'react-bootstrap'

import { Row, Col } from 'react-bootstrap'
import DRYCOM from '../Encabezado/DRYCOM.jpg'
import Image from 'react-bootstrap/Image'


export class Header extends Component{
    render(){
        return(
            <div>
            <Row>
                <Col></Col>
                <Col className="d-flex justify-content-center">
                <Image src={DRYCOM} width="65%"></Image>
                </Col>
                <Col></Col>
            </Row>
            <Navbar collapseOnSelect bg="primary" expand="lg" variant="dark">
            <Navbar.Toggle className="justify-content-center"/>
            <Navbar.Collapse>
                <Nav>
                    <Nav.Link href="/en/Home">Home</Nav.Link>
                    <Nav.Link href="/en/ColdChain">Cold chain</Nav.Link>
                    <Nav.Link href="/en/Instrumental">Instrumental</Nav.Link>
                    <Nav.Link href="/en/MedicalInput">Medical Input</Nav.Link>
                    <NavDropdown title="Medicine">
                        <NavDropdown.Item href="/en/MedCommercial">Commercial</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/en/MedGeneric">Generic</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Furniture and equipment">
                        <NavDropdown.Item href="/en/FeClinical">Clinical</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/en/FeDiagnosis">Diagnosis</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/en/Emergency">Emergency</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/en/Bedridden">Bedridden</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/en/Gynecology">Gynecology</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/en/Intensive">Intensive</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/en/MedEducational">Medical/Educational</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/en/Neonatology">Neonatology</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/en/Weight">Weight and size</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/en/Rehabilitation">Rehabilitation</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/en/Operations">Operations room</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/en/FirstAids">First aids</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/en/ContactUs">Contact Us</Nav.Link>                  
                </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                Ir al sitio en <a href="/">Español</a>
                </Navbar.Text>
            </Navbar.Collapse>    
            </Navbar>
            </div>
        )
    }
}