import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Card, CardColumns, Row, Col } from 'react-bootstrap'
import img65 from '../EquipoMedico/img65.jpg'

export class Neonatology extends Component{
    render(){
        return(
            <div>
                <Header/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Neonatology</h1></Col>
                    <Col></Col>
                </Row>
                <br/>
                <CardColumns className="text-center">
                    <Card>
                        <Card.Img variant="top" src={img65} />
                        <Card.Body>
                            <Card.Title>Delivery room</Card.Title>
                        </Card.Body>
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}