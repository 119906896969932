import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Card, Pagination, CardColumns, Row, Col } from 'react-bootstrap'
import img3 from '../Rehabilitacion/img3.jpg'
import img4 from '../Rehabilitacion/img4.jpg'
import img5 from '../Rehabilitacion/img5.jpg'
import img6 from '../Rehabilitacion/img6.jpg'
import img7 from '../Rehabilitacion/img7.jpg'
import img8 from '../Rehabilitacion/img8.jpg'
import img9 from '../Rehabilitacion/img9.jpg'
import img11 from '../Rehabilitacion/img11.jpg'
import img28 from '../Rehabilitacion/img28.jpg'

export class Rehabilitation3 extends Component{
    render(){
        return(
            <div>
               <Header/>
               <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Rehabilitation</h1></Col>
                    <Col></Col>
                </Row>
                <br/>
                <Pagination className="justify-content-center" size="lg">
                <Pagination.Item href="/en/Rehabilitation">{1}</Pagination.Item>
                <Pagination.Item href="/en/Rehabilitation2">{2}</Pagination.Item>
                <Pagination.Item active>{3}</Pagination.Item>
                </Pagination>

                <CardColumns className="text-center">
                
                <Card>
                    <Card.Img variant="top" src={img3}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img4}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img5}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img6}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img7}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img8}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img9}/>
                    
                </Card>
                
                <Card>
                    <Card.Img variant="top" src={img11}/>
                    
                </Card>
                <Card>
                        <Card.Img variant="top" src={img28}/>
                        
                    </Card>
                
                </CardColumns>
                <br/>
                <Pagination className="justify-content-center" size="lg">
                <Pagination.Item href="/en/Rehabilitation">{1}</Pagination.Item>
                <Pagination.Item href="/en/Rehabilitation2">{2}</Pagination.Item>
                <Pagination.Item active>{3}</Pagination.Item>
                </Pagination>
                <Footer/> 
            </div>
        )
    }
}