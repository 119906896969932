import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { CardColumns, Row, Col, Card } from 'react-bootstrap'
import Maniquis1 from '../Educativo/Maniquis1.jpg'
import Maniqui2 from '../Educativo/Maniqui2.jpg'

export class MedEducational extends Component{
    render(){
        return(
            <div>
                <Header/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Medical/Educational</h1></Col>
                    <Col></Col>
                </Row>
                <br/>
                <CardColumns className="text-center">
                    <Card >
                        <Card.Img variant="top" src={Maniquis1}/>
                        <Card.Body>
                            <Card.Title>Educational mannequins</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card >
                        <Card.Img variant="top" src={Maniqui2}/>
                        <Card.Body>
                            <Card.Title>Mannequin Birthing Simulator</Card.Title>
                        </Card.Body>
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}