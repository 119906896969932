import img65 from './EquipoMedico/img65.jpg'
import {Footer} from './Footer'
import React, {Component} from 'react'
import { Card, CardColumns, Row, Col } from 'react-bootstrap'
import {Navigation} from './Navigation'

export class Neonatologia extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Neonatología</h1></Col>
                    <Col></Col>
                </Row>
                <br/>
                <CardColumns className="text-center">
                    <Card>
                        <Card.Img variant="top" src={img65} />
                        <Card.Body>
                            <Card.Title>Sala para partos</Card.Title>
                        </Card.Body>
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}