import {Footer} from './Footer'
import React, {Component} from 'react'
import { CardColumns, Row, Col, Card } from 'react-bootstrap'
import CajasFrias from './CadenaDeFrio/CajasFrias.jpg'
import Termos1 from './CadenaDeFrio/Termos1.jpg'
import {Navigation} from './Navigation'


export class CadenaFrio extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Cadena frio</h1></Col>
                    <Col></Col>
                </Row>
                <br/>
                <CardColumns className="text-center">
                    <Card >
                        <Card.Img variant="top" src={CajasFrias}/>
                        <Card.Body>
                            <Card.Title>Cajas frías</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card >
                        <Card.Img variant="top" src={Termos1}/>
                        <Card.Body>
                            <Card.Title>Termos</Card.Title>
                        </Card.Body>
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}