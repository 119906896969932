import img18 from './EquipoMedico/img18.jpg'
import img23 from './EquipoMedico/img23.jpg'
import img24 from './EquipoMedico/img24.jpg'
import img25 from './EquipoMedico/img25.jpg'
import img26 from './EquipoMedico/img26.jpg'
import img27 from './EquipoMedico/img27.jpg'
import img28 from './EquipoMedico/img28.jpg'
import img29 from './EquipoMedico/img29.jpg'
import img30 from './EquipoMedico/img30.jpg'
import img31 from './EquipoMedico/img31.jpg'
import img32 from './EquipoMedico/img32.jpg'
import img33 from './EquipoMedico/img33.jpg'
import img34 from './EquipoMedico/img34.jpg'
import img35 from './EquipoMedico/img35.jpg'
import img36 from './EquipoMedico/img36.jpg'
import img37 from './EquipoMedico/img37.jpg'
import img38 from './EquipoMedico/img38.jpg'
import img39 from './EquipoMedico/img39.jpg'
import img40 from './EquipoMedico/img40.jpg'
import img41 from './EquipoMedico/img41.jpg'
import {Footer} from './Footer'
import React, {Component} from 'react'
import { CardDeck, Card, Pagination, Container, Row, Col } from 'react-bootstrap'
import {Navigation} from './Navigation'

export class Insumo2 extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <Container><br/>
                <Pagination className="justify-content-center" size="lg">
                <Pagination.Item href="/insumo">{1}</Pagination.Item>
                <Pagination.Item active>{2}</Pagination.Item>
                <Pagination.Item href="/insumo3">{3}</Pagination.Item>
                </Pagination>
                <br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Esparadrapo de Seda</Card.Title>
                                <Card.Text>	1/2" x 10 1.25x10 ydas<br/>
                                            24 rollos box; 48 boxes/carton<br/>
                                            44x32. 5x33.5<br/>
                                            1" 2.5x10ydas 12 rolls/box<br/>
                                            48 boxes/carton<br/>
                                            44x32.5x33.5<br/>
                                            2"5x10ydas 6 rolls/box<br/>
                                            48 boxes/carton 44x32.5x33.5
                                </Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img18} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Micropore</Card.Title>
                                <Card.Text>1.25x10ydas 24rolls/box;<br/>
                                            48 boxes/carton 44x32.5x33<br/>
                                            2.5x10yds. 12 rolls/box;<br/>
                                            48 boxes/carton 44x32 5x33.5<br/>
                                            5x10yds. 6 rolls box;<br/>
                                            48 boxes/carton 44x32.5x33.5
                                            7.5X10yds.</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img23} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Mascarilla</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img24} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Pericraneales</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img25} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Bolsa Para Orina</Card.Title>
                                <Card.Text>	Pediatrica</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img26} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Ropa para Cirujano</Card.Title>
                                <Card.Text>Descartable, Variedad de colores</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img27} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Ropa para Cirujano</Card.Title>
                                <Card.Text>Descartable, Variedad de colores</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img28} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Sonda Estomacal</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img29} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Sonda Foley</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img30} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Sonda Levin</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img31} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Sonda Foley</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img32} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Sonda Nelaton</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img33} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Sonda Rectal</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img34} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Sondas Estomacales</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img35} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Tubos Endotraqueales</Card.Title>
                                <Card.Text>Variedad de Estilos</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img36} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Tubos Endotraqueales</Card.Title>
                                <Card.Text>	Variedad de Estilos</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img37} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Equipo de Suero</Card.Title>
                                <Card.Text>Ventilado en "Y"</Card.Text>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img38} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Jeringas Descartables</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img39} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </CardDeck><br/>
                <CardDeck>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Title className="color-texto">Guantes Descartables</Card.Title>
                                </Col>
                                <Col>
                                <Card.Img variant="top" src={img40} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                    <Card.Header><h5>Productos descartables</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Card.Title className="color-texto">Hisopos de Madera Descartable</Card.Title>
                                    <Card.Text>	Bolsa 100 Unidades</Card.Text>
                                    </Col>
                                    <Col>
                                    <Card.Img variant="top" src={img41} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                </CardDeck><br/>

                </Container>
            
                <Pagination className="justify-content-center" size="lg">
                <Pagination.Item href="/insumo">{1}</Pagination.Item>
                <Pagination.Item active>{2}</Pagination.Item>
                <Pagination.Item href="/insumo3">{3}</Pagination.Item>
                </Pagination>
                <Footer/>
            </div>
        )}}