import React, {Component} from 'react'
import { Col, Row, Carousel, Image, Container } from 'react-bootstrap';
import {Header} from './Header'
import  EquipoMedico  from '../Inicio/EquipoMedico.jpg';
import  Medicamentos  from '../Inicio/Medicamentos.jpg';
import  Instrumental  from '../Inicio/Instrumental.jpg';
import Calidad from '../Encabezado/Calidad.png'
import Tarjetas from '../Encabezado/Tarjetas.png'
import Marcas  from '../Inicio/Marcas.jpg';
import {Footer} from './Footer'

export class EngHome extends Component{
    render(){
        return(
            <div>
            <Header/>
            <Container>
                <Carousel className="carousel slide carousel-fade fondo-ca">
                    <Carousel.Item>
                        <a href="/equipoMedico">
                            <Image
                            className="d-block mx-auto img-fluid"
                            width="64%"
                            src= { EquipoMedico }
                            alt="Eq medico"
                            />    
                        </a>
                        <Carousel.Caption>
                        <h3 className="text-light bg-dark">Equipo medico</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block mx-auto img-fluid"
                        width="80%"
                        src= { Medicamentos }
                        alt="Third slide"
                        />
                        <Carousel.Caption>
                        <h3 className="text-light">Medicamento</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <a href="/instrumental">
                            <img
                            className="d-block mx-auto img-fluid"
                            width="71%"
                            src= { Instrumental }
                            alt="Third slide"
                            />
                        </a>
                        <Carousel.Caption>
                        <h3 className="text-light bg-dark">Instrumental</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
                <br></br>
            <div className="fondo-texto">
                <h1>¡Welcome to Drycom!</h1>
                <p className="text-justify mt-4 mb-4">
                    We are a business dedicated to the distribution of the <strong>wholesale</strong> of medical equipment, laboratory equipment and medications, such as the private area to the government in Guatemala. We are the pioneers in the distribution of equipment and medications of high quality and trustworthy. We count with:
                </p>
            
                <Row>
                <Col>
                <ul >
                    <li>Bathroom safety</li>
                    <li>Commercial medications</li>
                    <li>Diagnostic equipment</li>
                    <li>Disposable products</li>
                    <li>Equipment for cold chain</li>
                    <li>Exam and lamps</li>
                    <li>Furniture and medical equipment</li>
                </ul>
                </Col>
                
                <Col>
                <ul>
                    <li>Furniture for rehabilitation</li>
                    <li>Generic medications</li>
                    <li>Instrumentation stainless steel</li>
                    <li>Medical products</li>
                    <li>Sutures</li>
                    <li>Weight and size</li>
                    <li>Everything related to the health sector</li>
                </ul>
                </Col>
                </Row>
                <p>Contact us for more information</p>
                
            </div>
            <br></br>
            </Container>
            <Row className="fondo-texto">
                <Col className="border-right border border-dark">
                    <h2 className="text-center">Quality in our products</h2>
                    <br></br>
                    <p className="text-center">
                    <br/><br/>
                    <Image src={Calidad} width="70%" className="border-0 rounded"></Image>
                    </p>
                </Col>
                <Col className="border-dark fondo-ca">
                    <br></br>
                    <div className="fb-page d-flex justify-content-center" data-href="https://www.facebook.com/Drycom-Drogueria-Distribuidora-351242224953844/" data-tabs="timeline" data-width="450" data-height="700" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/Drycom-Drogueria-Distribuidora-351242224953844/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Drycom-Drogueria-Distribuidora-351242224953844/">Drycom Drogueria - Distribuidora</a></blockquote></div>
                    <br></br>                
                </Col>
                <Col className="border-right border border-dark">
                    <h2 className="text-center">Some of our trademarks</h2>
                    <br/><br/>
                    <br/><br/>
                    <br/><br/>
                    <Image src={Marcas} width="100%"/>
                </Col>
            </Row>
            <br></br>
            <Row>
            <Col></Col>
            <Col className="d-flex justify-content-center">
            <Image src={Tarjetas} width="60%"></Image>
            <br />
            </Col>
            <Col></Col>
            </Row>
            <br></br>
            <Footer/>
            </div>
        )
    }
}