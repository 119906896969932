import img63 from './EquipoMedico/img63.jpg'
import {Footer} from './Footer'
import React, {Component} from 'react'
import { Card, CardColumns, Row, Col } from 'react-bootstrap'
import {Navigation} from './Navigation'


export class Clinico extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Clínico</h1></Col>
                    <Col></Col>
                </Row>
                <br/>
                    <CardColumns className="text-center">
                    <Card>
                        <Card.Img variant="top" src={img63} />
                        <Card.Body>
                            <Card.Title>Consultas y examenes</Card.Title>
                        </Card.Body>
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}