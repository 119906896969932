import img13 from './Rehabilitacion/img13.jpg'
import img14 from './Rehabilitacion/img14.jpg'
import img15 from './Rehabilitacion/img15.jpg'
import img16 from './Rehabilitacion/img16.jpg'
import img17 from './Rehabilitacion/img17.jpg'
import img18 from './Rehabilitacion/img18.jpg'
import img19 from './Rehabilitacion/img19.jpg'
import img20 from './Rehabilitacion/img20.jpg'
import img21 from './Rehabilitacion/img21.jpg'
import img22 from './Rehabilitacion/img22.jpg'
import img23 from './Rehabilitacion/img23.jpg'
import img24 from './Rehabilitacion/img24.jpg'
import {Footer} from './Footer'
import React, {Component} from 'react'
import { Card, Pagination, CardColumns, Row, Col } from 'react-bootstrap'
import {Navigation} from './Navigation'


export class Rehabilitacion2 extends Component{
    render(){
        return(
            <div>
                <Navigation/>
            <br/>
            <Row>
                <Col></Col>
                <Col className="text-center"><h1>Rehabilitación</h1></Col>
                <Col></Col>
            </Row>
                <br/>
            <Pagination className="justify-content-center" size="lg">
            <Pagination.Item href="/rehabilitacion">{1}</Pagination.Item>
            <Pagination.Item active>{2}</Pagination.Item>
            <Pagination.Item href="/rehabilitacion3">{3}</Pagination.Item>
            </Pagination>
            <CardColumns className="text-center">
            <Card>
                <Card.Img variant="top" src={img13}/>
                
            </Card>
            <Card>
                <Card.Img variant="top" src={img14}/>
                
            </Card>
            <Card>
                <Card.Img variant="top" src={img15}/>
                
            </Card>
            <Card>
                    <Card.Img variant="top" src={img16}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img17}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img18}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img19}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img20}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img21}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img22}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img23}/>
                    
                </Card>
                <Card>
                    <Card.Img variant="top" src={img24}/>
                    
                </Card>
            </CardColumns><br/>
            <Pagination className="justify-content-center" size="lg">
            <Pagination.Item href="/rehabilitacion">{1}</Pagination.Item>
            <Pagination.Item active>{2}</Pagination.Item>
            <Pagination.Item href="/rehabilitacion3">{3}</Pagination.Item>
            </Pagination>
            <Footer/>
            </div>
        )
    }
}