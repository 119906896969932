import {Footer} from './Footer'
import React, {Component} from 'react'
import { CardColumns, Row, Col, Card } from 'react-bootstrap'
import PesoTalla1 from './PesoTalla/PesoTalla1.jpg'
import PesoTalla2 from './PesoTalla/PesoTalla2.jpg'
import PesoTalla3 from './PesoTalla/PesoTalla3.jpg'
import PesoTalla4 from './PesoTalla/PesoTalla4.jpg'
import {Navigation} from './Navigation'

export class Talla extends Component{
    render(){
        return(
            <div>
                <Navigation/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Peso y Talla</h1></Col>
                    <Col></Col>
                </Row>
                <br/>
                
                <CardColumns className="text-center">
                    <Card >
                        <Card.Img variant="top" src={PesoTalla1}/>
                        <Card.Body>
                            <Card.Title>Tallimetro/Infantometro</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card >
                        <Card.Img variant="top" src={PesoTalla2}/>
                        <Card.Body>
                            <Card.Title>Tallimetro/Infantometro</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card >
                        <Card.Img variant="top" src={PesoTalla3}/>
                        <Card.Body>
                            <Card.Title>Balanza Pedriátrica</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card >
                        <Card.Img variant="top" src={PesoTalla4}/>
                        <Card.Body>
                            <Card.Title>Balanza para adulto y balanza de piso</Card.Title>
                        </Card.Body>
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}