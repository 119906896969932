import React, {Component} from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { CardColumns, Row, Col, Card } from 'react-bootstrap'
import PesoTalla1 from '../PesoTalla/PesoTalla1.jpg'
import PesoTalla2 from '../PesoTalla/PesoTalla2.jpg'
import PesoTalla3 from '../PesoTalla/PesoTalla3.jpg'
import PesoTalla4 from '../PesoTalla/PesoTalla4.jpg'

export class Weight extends Component{
    render(){
        return(
            <div>
                <Header/>
                <br/>
                <Row>
                    <Col></Col>
                    <Col className="text-center"><h1>Weight and Size</h1></Col>
                    <Col></Col>
                </Row>
                <br/>
                
                <CardColumns className="text-center">
                    <Card >
                        <Card.Img variant="top" src={PesoTalla1}/>
                        <Card.Body>
                            <Card.Title>Heightmeter/Infantometer</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card >
                        <Card.Img variant="top" src={PesoTalla2}/>
                        <Card.Body>
                            <Card.Title>Heightmeter/Infantometer</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card >
                        <Card.Img variant="top" src={PesoTalla3}/>
                        <Card.Body>
                            <Card.Title>Pediatric Balance</Card.Title>
                        </Card.Body>
                    </Card>
                    <Card >
                        <Card.Img variant="top" src={PesoTalla4}/>
                        <Card.Body>
                            <Card.Title>Adult scale and floor scale</Card.Title>
                        </Card.Body>
                    </Card>
                </CardColumns>
                <Footer/>
            </div>
        )
    }
}
